import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ProductoService } from 'src/app/components/shared/services/producto.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Sucursal } from '../modals/sucursal.model';

@Component({
  selector: 'app-sucursal-options',
  templateUrl: './sucursal-options.component.html',
  styleUrls: ['./sucursal-options.component.sass']
})

export class SucursalOptionsComponent implements OnInit {
  public sucursalesList: Sucursal[] = [];
  public recogida: boolean;

  ngOnInit(): void {  }

  constructor(private productService: ProductoService, private route: ActivatedRoute) {
    this.route.params.subscribe(
      (params: Params) => {
        this.productService.getSucursales().subscribe(data => {
          this.sucursalesList = data;
        })
      }
    )
  }

  form = new FormGroup({
    website: new FormControl('', Validators.required)
  });

  get f(){
    return this.form.controls;
  }

  guardar(sucursal : number): void {
    if(this.recogida){
      localStorage.setItem('sucursal', sucursal.toString());
    } else {
      localStorage.setItem('sucursal', "2");
    }
  }
}
