import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ClienteService } from '../../shared/services/cliente.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent implements OnInit {
  passwordForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    nuevaPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
  })

  constructor(private clienteService : ClienteService, private router : Router) { }

  ngOnInit(): void {

  }


  onSubmit() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    if (this.passwordForm.valid) {
      var code = localStorage.getItem('Codigo');

      this.clienteService.changePassword(code, this.passwordForm.get('password').value).subscribe(data => {
        if(data != '0'){
          Toast.fire({
            icon: 'success',
            title: 'Contraseña cambiada exitósamente.'
          });

          this.router.navigateByUrl('/auth/login');
        }
        else
        {
          Toast.fire({
            icon: 'success',
            title: 'Código incorrecto.'
          });
        }
      });
    }
  }
}
