import { Component, OnInit, Input } from '@angular/core';
import { ProductoService } from 'src/app/components/shared/services/producto.service';
import { Producto } from 'src/app/modals/producto.model';
@Component({
  selector: 'app-product-vertical-food',
  templateUrl: './product-vertical-food.component.html',
  styleUrls: ['./product-vertical-food.component.sass']
})
export class ProductVerticalFoodComponent implements OnInit {

  contentLoaded = false;
  @Input() products: Producto[];

   constructor(private productService: ProductoService ) { }

   ngOnInit() {
     this.productService.getProducts()
     .subscribe(
      (product: Producto[]) => {
        this.products = product.filter(item => item.area == 'food')
      }
    )

     setTimeout(() => {
       this.contentLoaded = true;
     }, 3000);
   }

}

