import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Cliente } from 'src/app/modals/cliente.model';
import { Orden } from 'src/app/modals/orden.model';
import Swal from 'sweetalert2';
import { CarritoService } from '../../shared/services/carrito.service';
import { OrdenService } from '../../shared/services/orden.service';
import { ModalPagosComponent } from '../modal-pagos/modal-pagos.component';

@Component({
  selector: 'app-historial-compras',
  templateUrl: './historial-compras.component.html',
  styleUrls: ['./historial-compras.component.sass']
})
export class HistorialComprasComponent implements OnInit {
  orders : Orden[] = [];
  Cliente : Cliente;
  procesing : boolean;
  contentLoaded : boolean = false;

  constructor(public cartService: CarritoService, private ordenService: OrdenService,
    private router: Router, public dialog: MatDialog) {
    this.Cliente = JSON.parse(localStorage.getItem('CustomerCM'));
    this.ordenService.getOrdenes(this.Cliente.codigo).subscribe(data => {
      this.orders = data;
      console.log(data);
      this.contentLoaded = true;
    });
  }

  ngOnInit(): void {

  }

  public pagar(orden : Orden) {
    this.procesing = true

    const dialogRef = this.dialog.open(ModalPagosComponent, {
      width: '400px',
      data: orden
    })

    dialogRef.afterClosed().subscribe(result => {
      this.procesing = false
    });
  }
}
