import { Component, OnInit, ViewChild } from '@angular/core';
import { Producto } from 'src/app/modals/producto.model';
import { CartItem } from 'src/app/modals/cart-item';
import { CarritoService } from '../services/carrito.service';
import { SidebarMenuService } from '../sidebar/sidebar-menu.service';

@Component({
  selector: 'app-header-six',
  templateUrl: './header-six.component.html',
  styleUrls: ['./header-six.component.sass']
})
export class HeaderSixComponent implements OnInit {

  public sidenavMenuItems:Array<any>;

  public currencies = ['USD', 'EUR'];
  public currency:any;
  public flags = [
    { name:'English', image: 'assets/images/flags/gb.svg' },
    { name:'German', image: 'assets/images/flags/de.svg' },
    { name:'French', image: 'assets/images/flags/fr.svg' },
    { name:'Russian', image: 'assets/images/flags/ru.svg' },
    { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag:any;




  public shoppingCartItems: CartItem[] = [];


  constructor(private cartService: CarritoService) {
    this.cartService.getItems().subscribe(shoppingCartItems =>
      {
      this.shoppingCartItems = shoppingCartItems
    }
    );
  }

  ngOnInit() {

  }

}














