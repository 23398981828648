import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeThreeComponent } from './components/shop/home-three/home-three.component';

import { PoliticaprivacidadComponent } from './components/politicas/politicaprivacidad/politicaprivacidad.component';
import { TerminoscondicionesComponent } from './components/politicas/terminoscondiciones/terminoscondiciones.component';
import { PoliticadevolucionesComponent } from './components/politicas/politicadevoluciones/politicadevoluciones.component';
import { FilosofiaComponent } from './components/nosotros/filosofia/filosofia.component';
import { HistoriaComponent } from './components/nosotros/historia/historia.component';

import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { ShoppingCartComponentComponent } from './components/shopping-cart-component/shopping-cart-component.component';
import { WishlistComponent } from './components/wishlist/wishlist.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ContactComponent } from './components/contact/contact.component';
import { EditProfileComponent } from './components/auth/edit-profile/edit-profile.component';
import { EditAddressComponent } from './components/auth/edit-address/edit-address.component';
import { HistorialComprasComponent } from './components/auth/historial-compras/historial-compras.component';
import { AddAddressComponent } from './components/auth/add-address/add-address.component';
import { RestorePasswordComponent } from './components/auth/restore-password/restore-password.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { ValidateCodeComponent } from './components/auth/validate-code/validate-code.component';
import { RegisterXMayorComponent } from './components/auth/register-xmayor/register-xmayor.component';
import { ErrorPageComponent } from './components/auth/error-page/error-page.component';
import { OrderSuccessComponent } from './components/auth/order-success/order-success.component';
import { UpdateDataCustomerComponent } from './components/update-data-customer/update-data-customer.component';
import { OrderSuccess2Component } from './components/auth/order-success2/order-success2.component';

const appRoutes: Routes = [
  {
    path: 'shop/cart',
    component: ShoppingCartComponentComponent
  },
  {
    path: 'shop/wishlist',
    component: WishlistComponent
  },
  {
    path: 'shop/checkout',
    component: CheckoutComponent
  },

  {
    path: 'nosotros/contacto',
    component: ContactComponent
  },

  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'auth/register',
    component: RegisterComponent
  },
  {
    path: 'auth/registerXMayor',
    component: RegisterXMayorComponent
  },
  {
    path: 'auth/profile',
    component: ProfileComponent
  },
  {
    path: 'auth/editProfile',
    component: EditProfileComponent
  },
  {
    path: 'auth/addAddress',
    component: AddAddressComponent
  },
  {
    path: 'auth/editAddress/:id',
    component: EditAddressComponent
  },
  {
    path: 'auth/orderHistory',
    component: HistorialComprasComponent
  },
  {
    path: 'shop/orderSuccess',
    component: OrderSuccessComponent
  },
  {
    path: 'shop/orderSucces',
    component: OrderSuccess2Component
  },
  {
    path: 'auth/restorePassword',
    component: RestorePasswordComponent
  },
  {
    path: 'auth/changePassword',
    component: ChangePasswordComponent
  },
  {
    path: 'auth/validateCode',
    component: ValidateCodeComponent
  },
  {
    path: 'data/update-data/:id',
    component: UpdateDataCustomerComponent
  },

  {
    path: 'politicas/politicadevoluciones',
    component: PoliticadevolucionesComponent
  },
  {
    path: 'politicas/politicaprivacidad',
    component: PoliticaprivacidadComponent
  },
  {
    path: 'politicas/terminoscondiciones',
    component: TerminoscondicionesComponent
  },
  {
    path: 'nosotros/filosofia',
    component: FilosofiaComponent
  },
  {
    path: 'nosotros/historia',
    component: HistoriaComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeThreeComponent
  },
  {
    path: '',
    component: HomeThreeComponent
  },
  {
    path: '**',
    component: ErrorPageComponent
    //redirectTo: 'home'
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
