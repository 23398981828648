import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Orden } from 'src/app/modals/orden.model';
import { Injectable } from '@angular/core';
import { ProductoService } from 'src/app/components/shared/services/producto.service';

@Injectable({
  providedIn: 'root'
})
export class OrdenService {
  constructor(private httpClient : HttpClient, private productService : ProductoService) {  }

  public saveOrden(orden : Orden): Observable<string> {
    return this.httpClient.post<string>(environment.apiUrl + '/api/ordenes/Save', orden);
  }

  public getOrdenes(Cliente : string): Observable<Orden[]> {
    return this.httpClient.get<Orden[]>(environment.apiUrl + `/api/ordenes/Get?Cliente=${Cliente}&Nivel=${this.productService.getNivel()}&Factor=${this.productService.getFactor()}`);
  }
}
