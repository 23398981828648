import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrupoService } from 'src/app/components/shared/services/grupo.service';
import { Dpto } from 'src/app/modals/dpto.model';
import { Grupo } from 'src/app/modals/grupo.model';

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.sass']
})
export class GruposComponent implements OnInit {
  @Output() groupChanged = new EventEmitter();
  @Input() gruposList : Grupo[] = [];

  constructor(private grupoService : GrupoService) {

  }

  ngOnInit(): void {  }

  groupSelect(event) {
    this.groupChanged.emit(
      event.value
    );
  }
}
