import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Injectable } from '@angular/core';
import { PaymentData } from 'src/app/modals/paymentData.model';
import { OrderPaymentData } from 'src/app/modals/orderPaymentData.model';
import { RespuestaTransferencia } from 'src/app/modals/respuesta.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
    constructor(private httpClient: HttpClient) {  }

    public pay(Data : PaymentData): Observable<RespuestaTransferencia> {
      return this.httpClient.post<RespuestaTransferencia>(environment.apiUrl + '/api/Payment/Pay', Data);
    }

    public payAgain(Data : PaymentData): Observable<RespuestaTransferencia> {
      return this.httpClient.post<RespuestaTransferencia>(environment.apiUrl + '/api/Payment/PayAgain', Data);
    }

    public getSecOrder(Data : string, OrdenID = 0): Observable<OrderPaymentData> {
      return this.httpClient.get<OrderPaymentData>(environment.apiUrl + `/api/Payment/GetSecOrder?Cliente=${Data}&Orden=${OrdenID}`);
    }
}
