import { Component, OnInit } from '@angular/core';
import { Producto } from 'src/app/modals/producto.model';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductoService } from '../../shared/services/producto.service';
import { CarritoService } from '../../shared/services/carrito.service';

@Component({
  selector: 'app-home-decor',
  templateUrl: './home-decor.component.html',
  styleUrls: ['./home-decor.component.sass']
})
export class HomeDecorComponent implements OnInit {
  contentLoaded = false;
  products: Producto[];
  public banners = [];

  shoppingCartItems: CartItem[] = [];
  wishlistItems  :   Producto[] = [];

  public featuredProducts: Array<Producto>;
  public onSaleProducts: Array<Producto>;
  public topRatedProducts: Array<Producto>;
  public newArrivalsProducts: Array<Producto>;

  public slides = [
    { title: 'Huge sale', subtitle: 'Up to 70%', image: 'assets/images/carousel/decor/banner1.jpg' },
    { title: 'Biggest discount', subtitle: 'Check the promotion', image: 'assets/images/carousel/decor/banner2.jpg' },
    { title: 'Biggest sale', subtitle: 'Dont miss it', image: 'assets/images/carousel/decor/banner3.jpg' },
    { title: 'Our best products', subtitle: 'Special selection', image: 'assets/images/carousel/decor/banner4.jpg' },
  ];


  public baners = [
    {

      "title": "Headphone",
      "subtitle": "Best bluetoot speakers ever. Don't miss it.",
      "image": "assets/images/background/baner_green.jpg" },
    {
      "title": "Multimedia",
      "subtitle1": ["Headphones", "Laptops", "Ipads", "smartphones", "Usb"],
      "image": "assets/images/product/baners-decor/tablet.png"
    },
    { "title": "Cosmetics",
      "subtitle": ["Headphones", "Laptops", "Ipads", "smartphones", "Usb"],
      "image": "assets/images/product/baners-decor/cosmetics.png" },
    { "title": "Home decors", "subtitle": ["Headphones", "Laptops", "Ipads", "smartphones", "Usb"
    ],
      "image":
      "assets/images/product/baners-decor/home.png"
    },
    {
      "title": "Electrics",
      "subtitle": ["Headphones", "Laptops", "Ipads", "smartphones", "Usb"],
       "image": "assets/images/product/baners-decor/electrics.png"
      }
  ]

  constructor(private productService: ProductoService, private cartService: CarritoService) { }

  ngOnInit() {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.productService.getProducts()
    .subscribe(
      (product: Producto[]) => {
        this.products = product.filter(item => item.area == 'decor');
      }
    )
    this.productService.getBanners()
    .subscribe(
      data => this.banners = data
    );
    document.documentElement.style.setProperty('--theme-deafult', '#ef6c00');

    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
  }

  public getBanner(index){
    return this.baners[index];
  }

  public getBgImage(index){
    let bgImage = {
      'background-image': index != null ? "url(" + this.baners[index].image + ")" : "url(https://via.placeholder.com/600x400/ff0000/fff/)"
    };
    return bgImage;
  }

}
