import { Component, OnInit, Input } from '@angular/core';
import { ProductoService } from 'src/app/components/shared/services/producto.service';
import { Producto } from 'src/app/modals/producto.model';

@Component({
  selector: 'app-product-vertical',
  templateUrl: './product-vertical.component.html',
  styleUrls: ['./product-vertical.component.sass']
})
export class ProductVerticalComponent implements OnInit {
  contentLoaded = false;
 @Input() products: Producto[];

 favorites: Producto[];
 novedades: Producto[];
 random: Producto[];

  constructor(public productService: ProductoService ) { }

  ngOnInit() {
    this.productService.getRandom()
    .subscribe(
      (product: Producto[]) =>  {
        this.random = product;
      }
    );

    this.productService.getNovedades()
    .subscribe(
      (product: Producto[]) =>  {
        this.novedades = product;
      }
    );

    this.productService.getFavorites()
    .subscribe(
      (product: Producto[]) =>  {
        this.favorites = product;
      }
    );

    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
  }

}
