import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Settings, AppSettings } from './components/shared/services/color-option.service';
import { Router, NavigationEnd } from '@angular/router';
import { SidenavMenu } from './components/shared/sidebar/sidebar-menu.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from './components/auth/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Cliente } from './modals/cliente.model';
import { MatAccordion } from '@angular/material/expansion';
import { Producto } from './modals/producto.model';
import { CartItem } from './modals/item-cart';
import { CarritoService } from './components/shared/services/carrito.service';
import { Observable } from 'rxjs';
import { ProductoService } from './components/shared/services/producto.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  styles:[
    `

    `
  ]
})
export class AppComponent {

  title = 'Casa Michel R.D.';
  public settings: Settings;
  public url: any;
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild('start') sidenav!: ElementRef;
  //@ViewChild('sidenav',{static: true}) referencia : any;

  navItems: SidenavMenu[] = [
    {
      displayName: 'Inicio',
      iconName: 'home',
      route: '/'
    },
    {
      displayName: 'Productos',
      iconName: 'movie_filter',
      children: [
        {
          displayName: 'Escolares y oficina',
          iconName: 'group',
          route: '/products/4/1'
        },
        {
          displayName: 'Fiestas y cumpleaños',
          iconName: 'speaker_notes',
          route: '/products/39/1'
        },
        {
          displayName: 'Juguetes',
          iconName: 'feedback',
          route: '/products/38/1'
        },
        {
          displayName: 'Regalos',
          iconName: 'feedback',
          route: '/products/41/1'
        }
        ,
        {
          displayName: 'Regalos',
          iconName: 'feedback',
          route: '/products/Ofertas/'
        }
      ]
    },
    {
      displayName: 'Politicas de empresa',
      iconName: 'report_problem',
      children: [
        {
          displayName: 'Política de privacidad',
          iconName: 'group',
          route: '/politicas/politicaprivacidad'
        },
        {
          displayName: 'Términos y condiciones',
          iconName: 'speaker_notes',
          route: '/politicas/terminoscondiciones',
        },
        {
          displayName: 'Políticas de devoluciones',
          iconName: 'feedback',
          route: '/politicas/politicadevoluciones'
        }
      ]
    },
    {
      displayName: 'Contacto',
      iconName: 'feedback',
      route: '/nosotros/contacto'
    },
    {
      displayName: 'Registrarse',
      iconName: 'person',
      route: '/auth/register'
    },
    {
      displayName: 'Wishlist',
      iconName: 'favorite_border',
      route: '/shop/wishlist'
    },
    {
      displayName: this.getCustomer(),
      iconName: 'person',
      route: '/auth/profile'
    }
  ];

  public search:string;

  products: Producto[];

  indexProduct: number;
  shoppingCartItems: CartItem[] = [];

  constructor(private cartService: CarritoService, private spinner: NgxSpinnerService, public appSettings: AppSettings,
    public router: Router, private apiService : ApiService,
    private cookieService: CookieService, public productService: ProductoService) {
      this.removeUser();
    this.settings = this.appSettings.settings;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });



    if(localStorage.getItem('CustomerCM') === null){
      var customer = new Cliente('0', 4, 1);
      localStorage.setItem('CustomerCM', JSON.stringify(customer));
    }

    if(localStorage.getItem('area') === null){
      localStorage.setItem('area', '0');
    }

    if(localStorage.getItem('dpto') === null){
      localStorage.setItem('dpto', '0');
    }
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
  }

  form = new FormGroup({
    website: new FormControl('', Validators.required)
  });
  public removeUser()
  {
    if (this.loggedIn())
    {
      this.navItems.splice(4,1);

    }
    else{
      this.navItems.splice(6,1);
      this.navItems.splice(5,1);

    }
  }

  getCustomer() : string {
    if( this.apiService.getCustomer()){
      return this.apiService.getCustomer().nombre;
    }
    return '';
  }

  logout() {

    this.apiService.logout();
    this.removeUser();
    location.reload();
  }

  loggedIn() : boolean {
    return this.apiService.isLoggedIn();
  }

  get f(){
    return this.form.controls;
  }

  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    document.documentElement.style.setProperty('--theme-deafult', '#62c639');
  }
  public updateCungrrency(curr) {
    this.productService.currency = curr;
  }

  public removeItem(item: CartItem) {
    this.cartService.removeFromCart(item);
  }

  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  public getQuantityItemsCart(): number {
    return this.cartService.getQuantityItems();
  }

  get getItems() :CartItem[]{
    this.cartService.getItems().subscribe(items => this.shoppingCartItems = items);
    return this.shoppingCartItems;
  }




}
