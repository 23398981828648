import { Producto, ColorFilter } from 'src/app/modals/producto.model';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProductoService } from 'src/app/components/shared/services/producto.service';
import { Component, OnInit } from '@angular/core';
import { DptoService } from 'src/app/components/shared/services/dpto.service';
import { Dpto } from 'src/app/modals/dpto.model';
import { Observable } from 'rxjs';
import { PersonajeService } from 'src/app/components/shared/services/personaje.service';
import { Personaje } from 'src/app/modals/personajes';

@Component({
  selector: 'app-products-search',
  templateUrl: './products-search.component.html'
  //styleUrls: ['./products-search.component.css']
})
export class ProductsSearchComponent implements OnInit {
  public sidenavOpen = true;
  public animation: any;   // Animation
  public sortByOrder = '';   // sorting
  public page: any;
  public current_page = 1;

  public tagsFilters: any[] = [];
  public viewType = 'grid';
  public viewCol = 25;
  public filterForm: FormGroup;
  public colorFilters: ColorFilter[] = [];
  public searched = false;

  public items: Producto[] = [];
  public allItems: Producto[] = [];
  public products: Producto[] = [];
  public tags: any[] = [];
  public colors: any[] = [];

  public dpto: number;
  public area: number;
  public search: string;

  public departmentList: Dpto[] = [];
  public dptos: Dpto[] = [];

  public personajeID: number = 0;
  public personajeList: Personaje[];

  constructor(
    private productService: ProductoService,
    private route: ActivatedRoute,
    private dptoService: DptoService,
    private router: Router,
    private personajeService: PersonajeService
  ) {




  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.search = params['search'];
      this.current_page = +params['page'] || 1;
      this.page = this.current_page;

      this.productService.getProductsBySearch(this.search).subscribe((products) => {
        this.allItems = products;
        this.products = products.slice(0, 8);
        this.searched = true;
      });
    });

    this.personajeService.getPersonajes().subscribe((data)=>{
      this.personajeList = data;
    })

  }

  public getDptos() {
    this.dptoService.getDptosByArea(this.area.toString()).subscribe((data) => {
      this.departmentList = data;
    });
  }

  public changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
  }

  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }

  // Update tags filter
  public updateTagFilters(tags: any[]) {
    this.tagsFilters = tags;
    this.animation = this.animation === 'fadeOut' ? 'fadeIn' : 'fadeOut'; // animation
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val;
    this.animation = this.animation === 'fadeOut' ? 'fadeIn' : 'fadeOut'; // animation
  }

  public onPageChanged(event) {
    this.current_page = event;
    this.page = this.current_page;
    this.router.navigateByUrl(`/productos/${this.search}/${event}`);
    window.scrollTo(0, 0);
  }

  public filterProducts() {
    this.allItems = this.products.filter((item: Producto) => {
      return item.descripcion.includes(this.search);
    });
  }

  // Update price filter
  public updatePriceFilters(price: any) {
    this.allItems = this.products.filter((item: Producto) => {
      return item.precio >= price.priceFrom && item.precio <= price.priceTo;
    });
  }

  onDptoChanged(newDpto) {
    this.allItems = newDpto === 0 ? this.products : this.products.filter((item) => item.dptoID === newDpto);
    this.dpto = newDpto;
  }

  onGroupsChanged(newGroup) {
    this.allItems = newGroup === 0 ? this.products : this.products.filter((item) => item.grupoID === newGroup);
  }


  onPersonajeChanged(newPersonaje) {
    this.allItems = newPersonaje === 0 ? this.products : this.products.filter((item) => item.personajeId === newPersonaje);
    this.personajeID = newPersonaje;
  }

}
