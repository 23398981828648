import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Area } from 'src/app/modals/area.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

    constructor(private httpClient: HttpClient) {

    }

    public getAreas(): Observable<Area[]> {
      return this.httpClient.get<Area[]>(environment.apiUrl + '/api/areas/Get');
    }
}
