export class Cliente {
  public codigo?: string;
  public nombre?: string;
  public nombreComercial?: string;
  public nombrePropietario?: string;
  public apellidos?: string;
  public email?: string;
  public password?: string;
  public confirmarPassword?: string;
  public nuevaPassword?: string;
  public empleador?: number;
  public rNC?: string;
  public cedula?: string;

  public direccion?: string;
  public provincia?: number;
  public provinciaID?: number;
  public ciudad?: string;
  public ciudadID?: number;
  public distritoMunicipal?: string;
  public distritoMunicipalID?: number;
  public seccion?: string;
  public seccionID?: number;
  public sector?: string;
  public sectorID?: number;
  public telefono1?: string;
  public tel1tp?: number;
  public telefono2?: string;
  public tel2tp?: number;
  public telefono3?: string;
  public tel3tp?: number;

  public comentarios?: string;
  public activo?: boolean;
  public dias?: number;
  public ncf?: number;
  public fecha?: Date;
  public tipo?: number;
  public limite?: number;
  public contacto?: string;

  public nivelDist?: number;
  public nivel?: number;
  public factor?: number;
  public factorDist?: number;

  constructor(codigo? : string, nivel? : number, factor? : number) {
    this.codigo = codigo;
    this.nivel = nivel;
    this.factor = factor;
  }
}
