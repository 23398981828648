import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Ciudad } from 'src/app/modals/ciudad.model'
import { Cliente } from 'src/app/modals/cliente.model'
import { DistritoMunicipal } from 'src/app/modals/distrito-municipal.model'
import { Dpto } from 'src/app/modals/dpto.model'
import { Provincia } from 'src/app/modals/provincia.model'
import { Seccion } from 'src/app/modals/seccion.model'
import { Sector } from 'src/app/modals/sector.model'
import Swal from 'sweetalert2'
import { ClienteService } from '../shared/services/cliente.service'
import { DireccionesService } from '../shared/services/direccion.service'

@Component({
  selector: 'app-update-data-customer',
  templateUrl: './update-data-customer.component.html',
  styleUrls: ['./update-data-customer.component.sass']
})
export class UpdateDataCustomerComponent implements OnInit {
  cliente: Cliente
  provincias: Provincia[] = []
  ciudades: Ciudad[] = []
  distritos: DistritoMunicipal[] = []
  secciones: Seccion[] = []
  sectores: Sector[] = []
  tipos: Dpto[] = []
  disable: boolean

  registerForm: FormGroup = this.fb.group({
    codigo: [0],
    referencia: ['', [Validators.required]],
    nombrePropietario: ['', [Validators.required]],
    nombreComercial: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    cedula: ['', [Validators.required, Validators.minLength(10)]],

    direccion: ['', [Validators.required]],
    provincia: [0, [Validators.required]],
    ciudad: [0, [Validators.required]],
    distritoMunicipal: [0, [Validators.required]],
    seccion: [0, [Validators.required]],
    sector: [0, [Validators.required]],
    telefono1: ['', [Validators.required, Validators.minLength(10)]],
    telefono2: ['', [Validators.required, Validators.minLength(10)]],
    telefono3: ['', [Validators.required, Validators.minLength(10)]],

    //rnc: ['', [Validators.required]],
    comentarios: ['', [Validators.required]],

    // factor: [0, [Validators.required]],
    // nivel: [1, [Validators.required]],
    // tipo: [1, [Validators.required]],
  })

  constructor(private fb: FormBuilder, private direccionService: DireccionesService,
    private clienteService: ClienteService, private router: Router,
    private route: ActivatedRoute) {
    this.direccionService.getProvincias().subscribe(data => this.provincias = data)

    this.clienteService.getTipos().subscribe(data => {
      this.tipos = data
    })

    this.route.params.subscribe(params => {
      this.clienteService.getClienteSIM(+params['id']).subscribe(data => {
        this.cliente = data

        this.registerForm.patchValue({
          codigo: +params['id'],
          nombrePropietario: data.nombrePropietario,
          nombreComercial: data.nombreComercial,
          cedula: data.cedula,
          telefono1: data.telefono1,
          telefono2: data.telefono2,
          telefono3: data.telefono3,
          email: data.email,

          provincia: data.provincia,
          ciudad: data.ciudad,
          distritoMunicipal: data.distritoMunicipal,
          seccion: data.seccion,
          sector: data.sector,
          direccion: data.direccion,

          //rnc: data.rNC,
          comentarios: data.comentarios,

          // nivel: data.nivel,
          // factor: data.factor,
          // tipo: data.tipo
        })
      })
    })
  }

  getFormValidationErrors() {
    Object.keys(this.registerForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.registerForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
        });
      }
    });
  }

  public getError(controlName: string): string {
    if (this.registerForm.get(controlName) != null) {
      if (this.registerForm.get(controlName).hasError('required')) {
        return `El campo ${controlName} es obligatorio.`
      } else if (this.registerForm.get(controlName).hasError('email')) {
        return `El campo ${controlName} debe ser un email válido.`
      } else if (this.registerForm.get(controlName).hasError('minlength')) {
        return `El campo ${controlName} debe tener como mínimo 8 caracteres.`
      }

      return 'Error'
    }

    return ''
  }

  cambioProvincia(provincia: number): void {
    this.direccionService.getCiudades(provincia).subscribe(data => this.ciudades = data)
  }

  cambioCiudad(ciudad: number): void {
    this.direccionService.getDistritosMunicipales(ciudad).subscribe(data => this.distritos = data)
  }

  cambioDistrito(seccion: number): void {
    this.direccionService.getSecciones(seccion).subscribe(data => this.secciones = data)
  }

  cambioSeccion(seccion: number): void {
    this.direccionService.getSectores(seccion).subscribe(data => this.sectores = data)
  }

  register(): void {
    this.getFormValidationErrors()

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    this.cliente = this.registerForm.value

    if (this.registerForm.valid) {
      this.clienteService.UpdateData(this.cliente).subscribe(data => {

        if (data != 0) {
          Toast.fire({
            icon: 'success',
            title: 'Guardada exitósamente.'
          })
          this.router.navigateByUrl('/home')
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Error al guardar, intente nuevamente.'
          })
        }
      })
    }
  }

  ngOnInit(): void {
  }

}
