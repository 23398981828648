import { Producto, ColorFilter } from 'src/app/modals/producto.model'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ProductoService } from 'src/app/components/shared/services/producto.service'
import { Component, OnInit } from '@angular/core'
import { Dpto } from 'src/app/modals/dpto.model'
import { DptoService } from 'src/app/components/shared/services/dpto.service'
import { GrupoService } from 'src/app/components/shared/services/grupo.service'
import { Grupo } from 'src/app/modals/grupo.model'
import { Personaje } from 'src/app/modals/personajes'
import { PersonajeService } from 'src/app/components/shared/services/personaje.service'

@Component({
  selector: 'app-product-left-sidebar',
  templateUrl: './product-left-sidebar.component.html',
  styleUrls: ['./product-left-sidebar.component.sass']
})

export class ProductLeftSidebarComponent implements OnInit {
  public sidenavOpen: boolean = true
  public animation: any   // Animation
  public sortByOrder: string = ''   // sorting
  public page: any
  public current_page: number
  public tagsFilters: any[] = []
  public viewType: string = 'grid'
  public viewCol: number = 25
  public filterForm: FormGroup
  public colorFilters: ColorFilter[] = []
  public notFound: boolean[] = []

  public items: Producto[] = []
  public allItems: Producto[] = []
  public products: Producto[] = []
  public departmentList: Dpto[] = []
  public gruposList: Grupo[] = []
  public tags: any[] = []
  public colors: any[] = []
  public personajeID: number = 0
  public personajeList: Personaje[] = []
  public dpto: number
  public depto: number
  public area: number
  public ofertas: number = 0
  public NuevasLlegadas: number = 0
  public search: string
  lastSegment: string;

  constructor(private productService: ProductoService, private route: ActivatedRoute,
    private router: Router, public dptoService: DptoService,
    private grupoService: GrupoService,
    private personajeService: PersonajeService) {

    this.lastSegment = this.route.snapshot.url[this.route.snapshot.url.length - 2].path;
    console.log(this.route);
    console.log(this.lastSegment);
    this.route.params.subscribe(
      (params: Params) => {
        this.area = params['area']
        this.dpto = params['dpto']
        this.current_page = params['page']
        this.page = params['page']
        console.log("paramns");
        this.dptoService.getDptosByArea(this.area.toString()).subscribe(dptos => {
          this.departmentList = dptos
        });

        if (this.dpto) {
          this.grupoService.getGruposByDepartment(JSON.parse(localStorage.getItem('dpto')).id).subscribe(grupos => {
            this.gruposList = grupos;
          });
        } else if (this.lastSegment === "Ofertas") {
          this.productService.getProductsByOfertas().subscribe(data => {
            this.allItems = data;
            console.log(data)
            this.products = data.slice(0.8)
          })
        }
        if (this.lastSegment === "NuevasLlegadas") {

          this.productService.getProductsByCompraUltimoMes().subscribe(products => {
            this.allItems = products
            this.products = products.slice(0.8)
          })
        }
        else if (this.lastSegment === "MasVendidos") {

          this.productService.getProductsByMasVendido15Dias().subscribe(products => {
            this.allItems = products
            this.products = products.slice(0.8)
          })
        }
        else if (this.lastSegment === "Preciosbajos") {

          this.productService.getProductsByPrecioBajo2Meses().subscribe(products => {
            this.allItems = products
            this.products = products.slice(0.8)
          })
        }
        else {
          if (this.dpto > 0) {
            this.productService.getProductsByDpto(this.dpto).subscribe(products => {
              this.allItems = products
              this.products = products.slice(0.8)
            })
          } else if (this.area > 0) {
            this.productService.getProductsByArea(this.area).subscribe(products => {
              this.allItems = products
              this.products = products.slice(0.8)
            })
          }
        }

      }
    )

    this.depto = JSON.parse(localStorage.getItem('dpto'))

    if (this.dpto > 0) {
      this.productService.getProductsByDpto(this.dpto).subscribe(products => {
        this.allItems = products
        this.products = products.slice(0.8)
      })
    } else if (this.area > 0) {
      this.productService.getProductsByArea(this.area).subscribe(products => {
        this.allItems = products
        this.products = products.slice(0.8)
      })
    }
    else if (this.ofertas > 0) {
      this.productService.getProductsByOfertas().subscribe(products => {
        this.allItems = products
        this.products = products.slice(0.8)
      })
    }
  }

  ngOnInit() {


    if (this.lastSegment === "Ofertas") {
      this.productService.getProductsByOfertas().subscribe(data => {
        this.allItems = data;
        console.log(data)
        this.products = data.slice(0.8)
      })
    }
    if (this.lastSegment === "NuevasLlegadas") {
      this.productService.getProductsByCompraUltimoMes().subscribe(products => {
        this.allItems = products
        this.products = products.slice(0.8)
      })
    }
    else if (this.lastSegment === "MasVendidos") {
      this.productService.getProductsByMasVendido15Dias().subscribe(products => {
        this.allItems = products
        this.products = products.slice(0.8)
      })
    }else if (this.lastSegment === "Preciosbajos") {
      this.productService.getProductsByPrecioBajo2Meses().subscribe(products => {
        this.allItems = products
        this.products = products.slice(0.8)
      })
    }
    if (this.dpto > 0) {
      this.productService.getProductsByDpto(this.dpto).subscribe(products => {
        this.allItems = products
        this.products = products.slice(0.8)
      })
    } else if (this.area > 0) {
      this.productService.getProductsByArea(this.area).subscribe(products => {
        this.allItems = products
        this.products = products.slice(0.8)
      })
    } else if (this.ofertas > 0) {
      this.productService.getProductsByOfertas().subscribe(products => {
        this.allItems = products
        this.products = products.slice(0.8)
      })
    }

    this.personajeService.getPersonajes().subscribe((data) => {
      this.personajeList = data;
    })
  }

  public getCount(): number {
    return this.allItems.length
  }

  public getDpto(): string {
    var d: Dpto
    d = JSON.parse(localStorage.getItem('dpto'))
    if (d != null && d.id > 0) {
      return ' > ' + d.nombre
    } else {
      return ''
    }
  }

  public getArea(): string {
    var a: Dpto
    a = JSON.parse(localStorage.getItem('area'))
    if (a != null) {
      return a?.nombre
    } else {
      return ''
    }
  }

  public changeViewType(viewType, viewCol) {
    this.viewType = viewType
    this.viewCol = viewCol
  }

  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = 'fadeIn'
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut'
  }

  // Update tags filter
  public updateTagFilters(tags: any[]) {
    this.tagsFilters = tags
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut() // animation
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut() // animation
  }

  public onPageChanged(event) {
    this.current_page = event
    this.page = event
    this.allItems
    if (this.dpto > 0) {
      this.router.navigateByUrl(`/products/${this.area}/${this.dpto}/${event}`)
    } else if (this.lastSegment === "Ofertas") {
      this.router.navigateByUrl(`/products/Ofertas/${event}`)
    } else if (this.lastSegment === "NuevasLlegadas") {
      this.router.navigateByUrl(`/products/NuevasLlegadas/${event}`)
    } else if (this.lastSegment === "MasVendidos") {
    this.router.navigateByUrl(`/products/MasVendidos/${event}`)
  } else if (this.lastSegment === "Preciosbajos") {
    this.router.navigateByUrl(`/products/Preciosbajos/${event}`)
  }
    else {
      this.router.navigateByUrl(`/products/${this.area}/${event}`)
    }
    window.scrollTo(0, 0)
  }

  // Update price filter
  public updatePriceFilters(price: any) {
    this.allItems = this.products.filter((item: Producto) => {
      return item.precio >= price.priceFrom && item.precio <= price.priceTo
    })
  }

  public filterProducts() {
    this.allItems = this.products.filter((item: Producto) => {
      return item.descripcion.includes(this.search)
    })
  }

  onDptoChanged(newDpto) {
    this.allItems = newDpto === 0 ? this.products : this.products.filter(
      item => item.dptoID === newDpto
    )
    this.dpto = newDpto
  }

  onGroupsChanged(newGroup) {
    this.allItems = newGroup === 0 ? this.products : this.products.filter(
      item => item.grupoID === newGroup
    )
  }


  onPersonajeChanged(newPersonaje) {
    this.allItems = newPersonaje === 0 ? this.products : this.products.filter((item) => item.personajeId === newPersonaje);
    this.personajeID = newPersonaje;
  }
}
