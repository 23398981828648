import { Component, OnInit } from '@angular/core';
import { Producto } from 'src/app/modals/producto.model';
import { ProductoService } from '../../shared/services/producto.service';
import { CartItem } from 'src/app/modals/item-cart';
import { CarritoService } from '../../shared/services/carrito.service';
import { SidenavMenu } from '../../shared/sidebar/sidebar-menu.model';
import { SucursalOptionsComponent } from 'src/app/sucursal-options/sucursal-options.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Sucursal } from 'src/app/modals/sucursal.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Area } from 'src/app/modals/area.model';

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.sass']
})

export class HomeThreeComponent implements OnInit {
  random: Producto[];
  favorites: Producto[];
  novedades: Producto[];

  sucursales: Sucursal[];

  public banners = [];

  shoppingCartItems: CartItem[] = [];
  wishlistItems  :   Producto[] = [];
  contentLoaded = false;

  public featuredProducts: Array<Producto>;
  public onSaleProducts: Array<Producto>;
  public topRatedProducts: Array<Producto>;
  public newArrivalsProducts: Array<Producto>;

  public slides = [
    { title: '', subtitle: '', image: 'assets/images/carousel/banner1.jpeg' },
    { title: '', subtitle: '', image: 'assets/images/carousel/banner2.jpeg' },
    { title: '', subtitle: '', image: 'assets/images/carousel/banner3.jpeg' }
  ];

  navItems: SidenavMenu[] = [
    {
      displayName: 'Home',
      iconName: 'recent_actors',
      children: [
        {
          displayName: 'Home-1',
          iconName: 'group',
          route: '/home/one'
        },
        {
          displayName: 'Home-2',
          iconName: 'speaker_notes',
          route: '/home/two',
        },
        {
          displayName: 'Home-3',
          iconName: 'feedback',
          route: '/home/three'
        }
      ]
    },
    {
      displayName: 'Products',
          iconName: 'feedback',
          route: '/home/products/all'
    },
    {
      displayName: 'Shop',
      iconName: 'movie_filter',
      children: [
        {
          displayName: 'Computers',
          iconName: 'group',
          children: [
            {
              displayName: 'Laptops',
              iconName: 'person',
              route: 'michael-prentice',
            },
            {
              displayName: 'Cables',
              iconName: 'person',
              route: 'stephen-fluin',
               },
            {
              displayName: 'Monitors',
              iconName: 'person',
              route: 'mike-brocchi',
           },
           {
            displayName: 'Tablets',
            iconName: 'person',
            route: 'mike-brocchi',
         },
         {
          displayName: 'Headsets',
          iconName: 'person',
          route: 'mike-brocchi',
       }
          ]
        },
        {
          displayName: 'Tv & Audio',
          iconName: 'speaker_notes',
          children: [
            {
              displayName: 'Tv',
              iconName: 'star_rate',
              route: 'material-design'
            },
            {
              displayName: 'Audio',
              iconName: 'star_rate',
              route: 'what-up-web'
            },
            {
              displayName: 'Video',
              iconName: 'star_rate',
              route: 'my-ally-cli'
            },
            {
              displayName: 'Dvd',
              iconName: 'star_rate',
              route: 'become-angular-tailer'
            }
          ]
        },
        {
          displayName: 'Phones',
          iconName: 'feedback',
          children: [
            {
              displayName: 'Mobile phones',
              iconName: 'star_rate',
              route: 'material-design'
            },
            {
              displayName: 'Power Bank',
              iconName: 'star_rate',
              route: 'what-up-web'
            },
            {
              displayName: 'Memory Cards',
              iconName: 'star_rate',
              route: 'my-ally-cli'
            },
            {
              displayName: 'Accesories',
              iconName: 'star_rate',
              route: 'become-angular-tailer'
            }
          ]
        },
        {
          displayName: 'Electronics',
          iconName: 'feedback',
          children: [
            {
              displayName: 'Washing Machines',
              iconName: 'star_rate',
              route: 'material-design'
            },
            {
              displayName: 'Water heater',
              iconName: 'star_rate',
              route: 'what-up-web'
            },
            {
              displayName: 'Cookers',
              iconName: 'star_rate',
              route: 'my-ally-cli'
            },
            {
              displayName: 'Cold stores',
              iconName: 'star_rate',
              route: 'become-angular-tailer'
            }
          ]
        }
      ]
    },
    {
      displayName: 'Blog',
      iconName: 'report_problem',
      children: [
        {
          displayName: 'Blog List',
          iconName: 'group',
          route: '/blog/blog-list'
        },
        {
          displayName: 'Blog Columns',
          iconName: 'speaker_notes',
          route: '/blog/blog-column',
        },
        {
          displayName: 'Blog Details',
          iconName: 'feedback',
          route: '/blog/blog-details'
        }
      ]
    },
    {
      displayName: 'Pages',
      iconName: 'report_problem',
      children: [
        {
          displayName: 'About Us',
          iconName: 'group',
          route: '/pages/about'
        },
        {
          displayName: 'FAQ',
          iconName: 'speaker_notes',
          route: '/pages/faq',
        },
        {
          displayName: 'Contact',
          iconName: 'feedback',
          route: '/pages/contact'
        },
        {
          displayName: 'Wishlist',
          iconName: 'group',
          route: '/pages/wishlist'
        },
        {
          displayName: 'Compare',
          iconName: 'speaker_notes',
          route: '/pages/compare',
        },
        {
          displayName: 'Checkout',
          iconName: 'feedback',
          route: '/pages/checkout'
        },
        {
          displayName: 'Cart',
          iconName: 'group',
          route: '/pages/cart'
        },
        {
          displayName: 'My Account',
          iconName: 'speaker_notes',
          route: '/pages/my-account',
        },
        {
          displayName: '404',
          iconName: 'feedback',
          route: '/pages/error'
        }
      ]
    },
    {
      displayName: 'Contact',
          iconName: 'feedback',
          route: '/pages/contact'
    }
  ];

  constructor(private httpClient: HttpClient, private productService: ProductoService, private cartService: CarritoService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);

    this.productService.getRandom()
    .subscribe(
      (product: Producto[]) =>  {
        this.random = product;
      }
    );

    this.productService.getNovedades()
    .subscribe(
      (product: Producto[]) =>  {
        this.novedades = product;
      }
    );

    this.productService.getFavorites()
    .subscribe(
      (product: Producto[]) =>  {
        this.favorites = product
      }
    )

    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
  }

  saveArea(area : string[]) {
    localStorage.setItem('area', JSON.stringify(area));
    localStorage.setItem('dpto', '0');
  }

  // Collection banner
  public discount = [{
    id: '38',
    image: 'assets/images/areas/38.jpeg',
    nombre: 'Juguetes',
    subtitle: '',
  }, {
    id: '4',
    image: 'assets/images/areas/4.jpeg',
    nombre: 'Escolares y Oficinas',
    subtitle: '',
  }]

}
