import { Injectable } from '@angular/core'
import { Producto } from 'src/app/modals/producto.model'
import { MatSnackBar } from '@angular/material/snack-bar'
import { CartItem } from 'src/app/modals/item-cart'
import { map } from 'rxjs/operators'
import { Observable, BehaviorSubject, Subscriber } from 'rxjs'
import { ApiService } from '../../auth/api.service'
import { MatDialog } from '@angular/material/dialog'
import { ModalLoginComponent } from '../../auth/modal-login/modal-login.component'

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("cartItemCM")) || []

@Injectable({
  providedIn: 'root'
})
export class CarritoService {

  // Array
  public cartItems: BehaviorSubject<CartItem[]> = new BehaviorSubject([])
  public observer: Subscriber<{}>

  constructor(public snackBar: MatSnackBar, private apiService: ApiService,
    public dialog: MatDialog) {
    this.cartItems.subscribe(
      products => products = products
    )
  }

  // Get Products
  public getItems(): Observable<CartItem[]> {
    const itemsStream = new Observable(observer => {
      observer.next(products)
      observer.complete()
    })
    return <Observable<CartItem[]>>itemsStream
  }

  // Add to cart
  public addToCart(product: Producto, quantity: number) {
    let message, status
    var item: CartItem | boolean = false

    if (this.apiService.isLoggedIn()) {
      // If Products exist
      let hasItem = products.find((items, index) => {
        if (items.product.codigo == product.codigo) {
          let qty = products[index].quantity + quantity
          let stock = this.calculateStockCounts(products[index], quantity)
          if (qty != 0 && stock) {
            products[index]['quantity'] = qty
            message = 'El producto ' + product.descripcion + ' ha sido agregado al carrito.'
            status = 'success'
            this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 })
          }
          return true
        }
      })

      // If Products does not exist (Add New Products)
      if (!hasItem) {
        item = { product: product, quantity: quantity }
        products.push(item)
        message = 'El producto ' + product.descripcion + ' ha sido agregado al carrito.'
        status = 'success'
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 })
      }

      localStorage.setItem("cartItemCM", JSON.stringify(products))
      return item

    } else {
      const dialogRef = this.dialog.open(ModalLoginComponent, {
        width: '400px'
      })
    }
  }

  // Calculate Producto stock Counts
  public calculateStockCounts(product: CartItem, quantity): CartItem | Boolean {
    let message, status
    let qty = product.quantity + quantity
    let stock = product.product.inventario
    if (stock < qty) {
      this.snackBar.open('No puedes agregar más de la cantidad disponible. Disponible ' + stock + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 })
      return false
    }
    return true
  }

  // Removed in cart
  public removeAllFromCart() {
    products = [];
    localStorage.removeItem("cartItemCM");
    
  }

  // Removed in cart
  public removeFromCart(item: CartItem) {
    if (item === undefined) return false
    const index = products.indexOf(item)
    products.splice(index, 1)
    localStorage.setItem("cartItemCM", JSON.stringify(products))
  }

  // SubTotal amount
  public getSubTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: CartItem[]) => {
      return products.reduce((prev, curr: CartItem) => {
        return prev + ((curr.product.precio * curr.quantity) - (curr.product.porITBIS * (curr.quantity * curr.product.precio)));
      }, 0);
    }));
  }

  // Itbis amount
  public getITBISAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: CartItem[]) => {
      return products.reduce((prev, curr: CartItem) => {
        return prev + (curr.product.porITBIS * (curr.quantity * curr.product.precio))
      }, 0)
    }))
  }

  // Delivery amount
  public getDelivery(): Observable<number> {
    const delivery = new Observable(observer => {
      if (localStorage.getItem('direccion') != '0') {
        observer.next(450)
      }
      else {
        observer.next(0.00)
      }

      observer.complete()
    })
    return <Observable<number>>delivery
  }

  // Total amount
  public getTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: CartItem[]) => {
      return products.reduce((prev, curr: CartItem) => {
        return prev + (curr.product.precio * curr.quantity)
      }, 0)
    }))
  }

  //Return the quantity items in a cart
  public getQuantityItems() : number{
    const products = JSON.parse(localStorage.getItem("cartItemCM")) || [];
    return products.length;
  }

  // Update Cart Value
  public updateCartQuantity(product: Producto, quantity: number): CartItem | boolean {
    return products.find((items, index) => {
      if (items.product.codigo == product.codigo) {
        let qty = products[index].quantity + quantity
        let stock = this.calculateStockCounts(products[index], quantity)
        if (qty != 0 && stock)
          products[index]['quantity'] = qty
        localStorage.setItem("cartItemCM", JSON.stringify(products))
        return true
      }
    })
  }

}
