import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Cliente } from 'src/app/modals/cliente.model';
import { Direccion } from 'src/app/modals/direccion.model';
import { Sucursal } from 'src/app/modals/sucursal.model';
import { DireccionesService } from '../../shared/services/direccion.service';
import { ProductoService } from '../../shared/services/producto.service';

@Component({
  selector: 'app-modal-envios',
  templateUrl: './modal-envios.component.html',
  styleUrls: ['./modal-envios.component.sass']
})
export class ModalEnviosComponent implements OnInit {
  public sucursalesList: Sucursal[] = [];
  public sucursal : string;

  public direccionesList: Direccion[] = [];
  public direccion: string;

  cliente: Cliente;

  sucursales : string[] = [];
  direcciones : string[] = [];

  constructor(public dialogRef : MatDialogRef<ModalEnviosComponent>,
    private direccionService : DireccionesService, private productService : ProductoService,
    private router : Router, @Inject(DOCUMENT) private _document: Document) {
    this.productService.getSucursales().subscribe(data => {
      this.sucursalesList = data;
      this.sucursal = data[0].nombre;
      localStorage.setItem('sucursal', data[0].id.toString());
    });

    this.cliente = JSON.parse(localStorage.getItem('CustomerCM'));

    this.direccionService.getDirecciones(this.cliente.codigo).subscribe(data => {
      this.direccionesList = data;
      this.direccion = data[0].alias;
      localStorage.setItem('direccion', data[0].id.toString());
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(): void {
    this._document.defaultView.location.reload();
  }

  public changeSucursal(sucursal : Sucursal){
    this.sucursal = sucursal.nombre.toString();
    localStorage.setItem('sucursal', JSON.stringify(sucursal.id));
  }

  public changeDireccion(direccion : Direccion){
    this.direccion = direccion.alias.toString();
    localStorage.setItem('direccion', JSON.stringify(direccion.id));
  }

  ngOnInit() {
    localStorage.setItem('sucursal', '15');
    localStorage.setItem('direccion', '0');
  }

}
