import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Ciudad } from 'src/app/modals/ciudad.model';
import { Cliente } from 'src/app/modals/cliente.model';
import { DistritoMunicipal } from 'src/app/modals/distrito-municipal.model';
import { Provincia } from 'src/app/modals/provincia.model';
import { Seccion } from 'src/app/modals/seccion.model';
import { Sector } from 'src/app/modals/sector.model';
import { ClienteService } from '../../shared/services/cliente.service';
import { DireccionesService } from '../../shared/services/direccion.service';
import { Router } from '@angular/router';
import { validarQueSeanIguales } from '../../shared/validators/equals.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit {
  cliente : Cliente;
  provincias : Provincia[] = [];
  ciudades : Ciudad[] = [];
  distritos : DistritoMunicipal[] = [];
  secciones : Seccion[] = [];
  sectores : Sector[] = [];
  disable : boolean;

  registerForm: FormGroup = this.fb.group({
    nombre:  ['', [ Validators.required ] ],
    apellidos: ['', [ Validators.required ] ],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [ Validators.required,Validators.minLength(8) ] ],
    confirmarPassword: ['', [ Validators.required, Validators.minLength(8) ] ],
    cedula: ['', [ Validators.required ] ],

    direccion: ['', [ Validators.required ] ],
    provincia: [0, [ Validators.required ] ],
    ciudad: [0, [ Validators.required ] ],
    distritoMunicipal: [0, [ Validators.required ] ],
    seccion: [0, [ Validators.required ] ],
    sector: [0, [ Validators.required ] ],
    telefono1: ['', [ Validators.required ] ],
    tel1tp: ['0', [ Validators.required ] ],
    telefono2: [''],
    tel2tp: ['0'],
    telefono3: [''],
    tel3tp: ['0'],

    ncf: ['1', [ Validators.required ] ],
    fecha: ['', [ Validators.required ] ],
    contacto: [''],
  // }, {
  //   validators: validarQueSeanIguales,
  });

  constructor(private fb: FormBuilder, private direccionService: DireccionesService,
    private clienteService : ClienteService, private router : Router) {
    this.direccionService.getProvincias().subscribe(data => this.provincias = data);
  }

  ngOnInit() {  }

  checarSiSonIguales(): boolean {
    return this.registerForm.hasError('noSonIguales') &&
      this.registerForm.get('password').dirty &&
      this.registerForm.get('confirmarPassword').dirty;
  }

  public getError(controlName: string): string {
    if(this.registerForm.get(controlName) != null){
      if (this.registerForm.get(controlName).hasError('required')) {
        return `El campo ${controlName} es obligatorio.`;
      } else if (this.registerForm.get(controlName).hasError('email')) {
        return `El campo ${controlName} debe ser un email válido.`;
      } else if (this.registerForm.get(controlName).hasError('minlength')) {
        return `El campo ${controlName} debe tener como mínimo 8 caracteres.`;
      }

      return 'error';
    }

    return 'error';
  }

  cambioProvincia(provincia : number) : void {
    this.direccionService.getCiudades(provincia).subscribe(data => this.ciudades = data);
  }

  cambioCiudad(ciudad : number) : void {
    this.direccionService.getDistritosMunicipales(ciudad).subscribe(data => this.distritos = data);
  }

  cambioDistrito(seccion : number) : void {
    this.direccionService.getSecciones(seccion).subscribe(data => this.secciones = data);
  }

  cambioSeccion(seccion : number) : void {
    this.direccionService.getSectores(seccion).subscribe(data => this.sectores = data);
  }

  register(): void {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    this.cliente = this.registerForm.value;

    this.clienteService.RegisterCliente(this.cliente).subscribe(data => {

      if(data != 0){
        Toast.fire({
          icon: 'success',
          title: 'Guardada exitósamente.'
        })
        this.router.navigateByUrl('/auth/login')
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Este Correo ya esta registrado, recupere su contraseña o intente nuevamente con un correo diferente.'
        })
      }
    });
  }

}
