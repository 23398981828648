import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicaprivacidad',
  templateUrl: './politicaprivacidad.component.html',
  styleUrls: ['./politicaprivacidad.component.sass']
})
export class PoliticaprivacidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
