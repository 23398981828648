import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Cliente } from 'src/app/modals/cliente.model';
import { Direccion } from 'src/app/modals/direccion.model';
import { Sucursal } from 'src/app/modals/sucursal.model';
import { ApiService } from '../api.service';
import { ModalEnviosComponent } from '../modal-envios/modal-envios.component';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.sass']
})
export class ModalLoginComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  })

  public sucursalesList: Sucursal[] = [];
  public sucursal : string;

  public direccionesList: Direccion[] = [];
  public direccion: string;

  cliente: Cliente;

  public loginError : String;

  sucursales : string[] = [];
  direcciones : string[] = [];

  constructor(private apiService : ApiService, private router : Router,
    public dialog : MatDialog, public dialogRef: MatDialogRef<ModalLoginComponent>) {  }

  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalEnviosComponent, {
      width: '400px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  onSubmit(){
    if(this.loginForm.valid){
      this.apiService.login(this.loginForm.value)
      .subscribe((data) => {
        if(data.status === 204){
          this.loginError = 'Usuario o Contraseña incorrectos.'
        }
        else if(data.status === 200 && !data.body.ErrorCode){
          localStorage.setItem('CustomerCM', JSON.stringify(data.body));

          this.dialogRef.close();
          this.openDialog();
          this.router.navigate(['/']);
        } else {
          this.loginError = data.body.message;
        }
      },
      error => this.loginError = error
      )
    }
  }
}
