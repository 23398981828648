import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Grupo } from 'src/app/modals/grupo.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GrupoService {
  constructor(private httpClient: HttpClient) { }

  public getGrupos(): Observable<Grupo[]> {
    return this.httpClient.get<Grupo[]>(environment.apiUrl + '/api/grupos/Get');
  }

  public getGruposByDepartment(department : number): Observable<Grupo[]> {
    return this.httpClient.get<Grupo[]>(environment.apiUrl + `/api/grupos/GetGruposByDepartment?Department=${department}`);
  }
}
