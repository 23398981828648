import { Component, OnInit } from '@angular/core';
import { Producto } from 'src/app/modals/producto.model';
import { ProductoService } from '../services/producto.service';
import { Observable } from 'rxjs';
import { CarritoService } from '../services/carrito.service';
import { CartItem } from 'src/app/modals/item-cart';

@Component({
  selector: 'app-shopping-widgets-two',
  templateUrl: './shopping-widgets-two.component.html',
  styleUrls: ['./shopping-widgets-two.component.sass']
})
export class ShoppingWidgetsTwoComponent implements OnInit {

  products: Producto[];
  indexProduct: number;

  public sidenavMenuItems: Array<any>;

  public shoppingCartItems: CartItem[] = [];

  constructor(private cartService: CarritoService, public productService: ProductoService) {

  }

  ngOnInit() {

  }

  public updateCurrency(curr) {
    this.productService.currency = curr;
  }

  public removeItem(item: CartItem) {
    this.cartService.removeFromCart(item);
  }

  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  public getQuantityItemsCart(): number {
    return this.cartService.getQuantityItems();
  }

  get getItems() :CartItem[]{
    this.cartService.getItems().subscribe(items => this.shoppingCartItems = items);
    return this.shoppingCartItems;
  }

}
