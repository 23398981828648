import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CarritoService } from 'src/app/components/shared/services/carrito.service';
import { ProductoService } from 'src/app/components/shared/services/producto.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Producto } from 'src/app/modals/producto.model';
import { ProductDialogComponent } from '../product-dialog/product-dialog.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.sass']
})
export class ProductComponent implements OnInit {

  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input() product: Producto;

  constructor(private cartService: CarritoService, public productsService: ProductoService, private wishlistService: WishlistService, private dialog: MatDialog, private router: Router) { }

  ngOnInit() {

  }

  // Add to cart
  public addToCart(product: Producto, quantity: number = 1) {
    this.cartService.addToCart(product, quantity);
  }

  // Add to wishlist
  public addToWishlist(product: Producto) {
    this.wishlistService.addToWishlist(product);
  }

  // Add to compare
  public addToCompare(product: Producto) {
    this.productsService.addToCompare(product);
  }

  public openProductDialog(product) {
    let dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product) {
        this.router.navigate(['/products', product.codigo, product.descripcion]);
      }
    });
  }

}
