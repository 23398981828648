import { Component, OnInit } from '@angular/core';
import { RespuestaTransferencia } from 'src/app/modals/respuesta.model';
import { CarritoService } from '../../shared/services/carrito.service';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.sass']
})
export class OrderSuccessComponent implements OnInit {

  message : RespuestaTransferencia;

  constructor(private cartService : CarritoService) {
    this.cartService.removeAllFromCart();
    localStorage.removeItem("cartItemCM")
  }

  ngOnInit(): void {
    this.message = JSON.parse(localStorage.getItem('responseCode'));
  }

  deleteAll() : void {
    localStorage.removeItem('responseCode')
    localStorage.removeItem('area')
    localStorage.removeItem('dpto')
    this.cartService.removeAllFromCart()
  }

  getRespuestaTransaccion(responseCode: string): string {
    switch (responseCode) {
      case "01":
      case "02":
      case "05":
      case "08": return "Declinado.";
      case "03": return "El comercio es inválido.";
      case "04": return "Tarjeta de Crédito sospechosa.";
      case "07": return "Tarjeta de Crédito rechazada.";
      case "10": return "La aprobacion fué por un monto parcial.";
      case "11": return "Aprovado VIP.";
      case "12": return "La transaccion es inválida.";
      case "13": return "El monto es inválida.";
      case "14":
      case "42": return "La cuenta es inválida.";
      case "22":
      case "23":
      case "24":
      case "25":
      case "26":
      case "27":
      case "28":
      case "29":
      case "30":
      case "31":
      case "32":
      case "33":
      case "34":
      case "35":
      case "36":
      case "37":
      case "38":
      case "41":
      case "43": return "Transaccion No Aprobada.";
      case "39": return "La tarjeta es inválida.";
      case "51": return "Declinada, Fondos insuficientes.";
      case "54": return "Declinada, Tarjeta de crédito vencida.";
      case "62": return "Declinada, Tarjeta de crédito no activa.";
      case "91":
      case "97": return "Host No disponible.";
      case "98": return "Declinada, Excede Limete de Efectivo.";
      case "99": return "Error de CVV o CVC.";
      default: return "Se ha presentado un error.";
    }
  }

  getRespuestaTransaccion2(responseCode: string): string {
    switch (responseCode) {
      case "1821": return "Comercio Invalido.";
      case "1833": return "Comercio Inactivo.";
      case "1002": return "Tarjeta Sospechosa.";
      case "1003": return "Tarjeta Suspendida.";
      case "1004": return "Tarjeta robada - Recogida.";
      case "1905": return "Rechazada.";
      case "1012": return "Titular de la tarjeta no encontrado.";
      case "1014": return "Cuenta no Encontrada.";
      case "1813": return "Titular de la tarjeta no activo.";
      case "1814": return "Titular de la tarjeta no configurado.";
      case "1808": return "Tarjeta no configurada.";
      case "1842": return "Criptograma inválido.";
      case "1817": return "Limite de uso alcanzado.";
      case "1026": return "Bloque de pin no válido.";
      case "1816": return "Original no encontrado.";
      case "0002": return "Titular de la tarjeta no configurado.";
      case "0003": return "VIP aprobado.";
      case "1818": return "Error de configuracion.";
      case "1840": return "Producto de tarjeta desconocido.";
      case "1810": return "Cantidad no válida, cantidad alta.";
      case "1832": return "Cantidad alta.";
      case "1804": return "numero de tarjeta invalido.";
      case "1011": return "Tarjeta no encontrada.";
      case "1017": return "Anteriormente invertido, No tomo accion.";
      case "1018": return "Completado previamente, No tomo accion.";
      case "1019": return "La actividad previene la reversion, No tomo accion.";
      case "1020": return "La actividad evita el vacio, No tomo accion.";
      case "1021": return "Anteriormente anulado, No tomo accion.";
      case "1022": return "Previamente ajustado, No tomo accion.";
      case "1016": return "Fondos insuficientes.";
      case "1001": return "Tarjeta vencida.";
      case "1815": return "Titular de la tarjeta caducado.";
      case "1806": return "Tarjeta no activa.";
      case "1819": return "Terminal inválida.";
      case "1820": return "Terminal inactiva.";
      case "1875": return "Corte en proceso.";
      case "1891": return "Host inalcanzable.";
      case "1822": return "Terminal inactiva.";
      case "5004": return "Entidad duplicada.";
      case "1811": return "System Error, Transaction.";
      case "1812": return "System Error, Transaction.";
      case "1835": return "Error del servidor de autenticacion.";
      case "1834": return "El comprador no coincide.";
      case "1823": return "Adquirente inválido.";
      case "9999": return "RC no configurado.";
      case "1802": return "Campos faltantes.";
      case "1803": return "Campos extra.";
      case "1841": return "Punto final no configurado.";
      case "1836": return "Datos malos.";
      case "1015": return "Solicitud no válida.";
      case "1831": return "Contexto inválido.";
      default: return "Se ha presentado un error.";
    }
  }
}
