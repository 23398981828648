import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { Sucursal } from 'src/app/modals/sucursal.model';
import { Direccion } from 'src/app/modals/direccion.model';
import { Cliente } from 'src/app/modals/cliente.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalEnviosComponent } from '../modal-envios/modal-envios.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  })

  public sucursalesList: Sucursal[] = [];
  public sucursal : string;

  public direccionesList: Direccion[] = [];
  public direccion: string;

  cliente: Cliente;

  public loginError : String;

  sucursales : string[] = [];
  direcciones : string[] = [];

  constructor(private apiService : ApiService, private router : Router,
    public dialog : MatDialog) {

  }

  ngOnInit(){  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalEnviosComponent, {
      width: '400px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  onSubmit(){
    if(this.loginForm.valid){
      this.apiService.login(this.loginForm.value)
      .subscribe((data) => {
        if(data.status === 204){
          this.loginError = 'Usuario o Contraseña incorrectos.'
        }
        else if(data.status === 200 && !data.body.ErrorCode){
          localStorage.setItem('CustomerCM', JSON.stringify(data.body));

          //this.mostrarModal();
          this.openDialog();
          this.router.navigate(['/']);
        } else {
          this.loginError = data.body.message;
        }
      },
      error => this.loginError = error
      )
    }
  }

  mostrarModal() {
    Swal.fire({
      title: 'Forma de entrega',
      text: "Elije tu forma de compra preferida",
      imageUrl: 'assets/images/logo-white.png',
      imageHeight: 120,
      imageAlt: 'Logo Casa Michel',
      showCancelButton: true,
      cancelButtonText: 'Recogida en tienda',
      confirmButtonColor: '#62c639',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Envío a domicilio',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Envío a domicilio',
          text: "Selecciona la dirección de envío deseada.",
          imageUrl: 'assets/images/logo-white.png',
          imageHeight: 120,
          imageAlt: 'Logo Casa Michel',
          input: 'select',
          inputOptions: this.direcciones,
          inputPlaceholder: 'Selecciona la dirección de envío.',
          showCancelButton: false,
          confirmButtonColor: '#62c639',
          confirmButtonText: 'Okay',
          allowOutsideClick: false
        }).then(function (result){
          localStorage.setItem('Direccion', result.value);
        });
      } else if(result.isDismissed) {
        Swal.fire({
          title: 'Recogida en tienda',
          text: "Selecciona la tienda más cercana.",
          imageUrl: 'assets/images/logo-white.png',
          imageHeight: 120,
          imageAlt: 'Logo Casa Michel',
          input: 'select',
          inputOptions: this.sucursales,
          inputPlaceholder: 'Selecciona la sucursal más cercana.',
          showCancelButton: false,
          confirmButtonColor: '#62c639',
          confirmButtonText: 'Okay',
          allowOutsideClick: false
        }).then(function (result){
          localStorage.setItem('Sucursal', result.value);
        });
      }
    })
  }
}
