import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Dpto } from 'src/app/modals/dpto.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DptoService {
    constructor(private httpClient: HttpClient) {  }

    private sucursal() : string {
      return localStorage.getItem('sucursal') || "17";
    }

    public getDptos(): Observable<Dpto[]> {
      return this.httpClient.get<Dpto[]>(environment.apiUrl + `/api/Departments/Get?Sucursal=${this.sucursal()}`);
    }

    public getDptosByArea(area : string): Observable<Dpto[]> {
      return this.httpClient.get<Dpto[]>(environment.apiUrl + `/api/Departments/GetDptosByArea?Area=${area}&Sucursal=${this.sucursal()}`);
    }
}
