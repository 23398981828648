import { Component, OnInit, ElementRef, ViewChild, HostListener} from '@angular/core';
import { Producto } from 'src/app/modals/producto.model';
import { CartItem } from 'src/app/modals/cart-item';
import { CarritoService } from '../services/carrito.service';
import { AppSettings, Settings } from '../services/color-option.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  private sticky: number;

  @ViewChild('fixedHeader') fixedHeader: ElementRef;

  ngAfterViewInit() {
    this.sticky = this.fixedHeader.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])

  onScroll() {
    this.StickyFunction();
  }

  StickyFunction() {

   const responsiveWidth = 768;

   if (window.innerWidth <= responsiveWidth) {
      if (window.pageYOffset >= this.sticky) {
        this.fixedHeader.nativeElement.classList.add("fixed-header");
      } else {
        this.fixedHeader.nativeElement.classList.remove("fixed-header");
      }
    }

  }

  searchForm = new FormGroup({
    search: new FormControl('', [Validators.required])
  })

  public sidenavMenuItems:Array<any>;
  public search:string;

  products: Producto[];

  indexProduct: number;
  shoppingCartItems: CartItem[] = [];
  public settings: Settings;

  constructor(private cartService: CarritoService, private router: Router, public appSettings:AppSettings) {
    this.settings = this.appSettings.settings;
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
  }

  ngOnInit() {  }

  onSubmit(){
    console.log(this.search);

    this.router.navigateByUrl(`productos/${this.search}/1`);
  }




}

