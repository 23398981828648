import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { DemoComponent } from './components/demo/demo.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { MainComponent } from './components/main/main.component';
import { AppRoutingModule } from './app-routing.module';
import { ShopModule } from './components/shop/shop.module';
import { SharedModule } from './components/shared/shared.module';
import { ColorOptionsComponent } from './components/color-options/color-options.component';
import { SucursalOptionsComponent } from './sucursal-options/sucursal-options.component';
import { PoliticaprivacidadComponent } from './components/politicas/politicaprivacidad/politicaprivacidad.component';
import { TerminoscondicionesComponent } from './components/politicas/terminoscondiciones/terminoscondiciones.component';
import { PoliticadevolucionesComponent } from './components/politicas/politicadevoluciones/politicadevoluciones.component';
import { FilosofiaComponent } from './components/nosotros/filosofia/filosofia.component';
import { HistoriaComponent } from './components/nosotros/historia/historia.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ApiService } from './components/auth/api.service';
import { CookieService } from 'ngx-cookie-service';
import { RegisterComponent } from './components/auth/register/register.component';
import { ShoppingCartComponentComponent } from './components/shopping-cart-component/shopping-cart-component.component';
import { WishlistComponent } from './components/wishlist/wishlist.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ContactComponent } from './components/contact/contact.component';
import { EditProfileComponent } from './components/auth/edit-profile/edit-profile.component';
import { HistorialComprasComponent } from './components/auth/historial-compras/historial-compras.component';
import { EditAddressComponent } from './components/auth/edit-address/edit-address.component';
import { AddAddressComponent } from './components/auth/add-address/add-address.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { RestorePasswordComponent } from './components/auth/restore-password/restore-password.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { ValidateCodeComponent } from './components/auth/validate-code/validate-code.component';
import { RegisterXMayorComponent } from './components/auth/register-xmayor/register-xmayor.component';
import { ModalEnviosComponent } from './components/auth/modal-envios/modal-envios.component';
import { ModalLoginComponent } from './components/auth/modal-login/modal-login.component';
import { ErrorPageComponent } from './components/auth/error-page/error-page.component';
import { OrderSuccessComponent } from './components/auth/order-success/order-success.component';
import { ModalPagosComponent } from './components/auth/modal-pagos/modal-pagos.component';
import { UpdateDataCustomerComponent } from './components/update-data-customer/update-data-customer.component';
import { OrderSuccess2Component } from './components/auth/order-success2/order-success2.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSidenavModule} from '@angular/material/sidenav';

// const maskConfigFunction: () => Partial<IConfig> = () => {
//   return {
//     validation: false,
//   };
// };

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    DemoComponent,
    MainComponent,
    ColorOptionsComponent,
    SucursalOptionsComponent,
    PoliticaprivacidadComponent,
    TerminoscondicionesComponent,
    PoliticadevolucionesComponent,
    FilosofiaComponent,
    HistoriaComponent,
    LoginComponent,
    ProfileComponent,
    RegisterComponent,
    ShoppingCartComponentComponent,
    WishlistComponent,
    CheckoutComponent,
    ContactComponent,
    EditProfileComponent,
    HistorialComprasComponent,
    EditAddressComponent,
    AddAddressComponent,
    RestorePasswordComponent,
    ChangePasswordComponent,
    ValidateCodeComponent,
    RegisterXMayorComponent,
    ModalEnviosComponent,
    ModalLoginComponent,
    ErrorPageComponent,
    OrderSuccessComponent,
    OrderSuccess2Component,
    ModalPagosComponent,
    UpdateDataCustomerComponent

  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    NgbModule,
    NgxMaskModule.forRoot(maskConfig),
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatNativeDateModule,
    SharedModule,
    ShopModule,
    NgxSkeletonLoaderModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxImgZoomModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatSidenavModule
  ],
  providers: [ApiService, CookieService],
  bootstrap: [AppComponent],
  entryComponents: [ModalEnviosComponent, ModalLoginComponent, ModalPagosComponent]
})
export class AppModule { }
