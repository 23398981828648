import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { ProductLeftSidebarComponent } from './products/product-left-sidebar/product-left-sidebar.component';
import { ProductsSearchComponent } from './products/products-search/products-search.component';

// Routes
const routes: Routes = [
  { path: 'products/:area/:dpto/:page', component: ProductLeftSidebarComponent },
  { path: 'products/:area/:page', component: ProductLeftSidebarComponent },
  { path: 'productos/:search/:page', component: ProductsSearchComponent },
  { path: 'product/:id', component: ProductDetailsComponent },
  { path: 'products/Ofertas/:page', component: ProductLeftSidebarComponent },
  { path: 'products/NuevasLlegadas/:page', component: ProductLeftSidebarComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
