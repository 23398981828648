import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Provincia } from 'src/app/modals/provincia.model';
import { Ciudad } from 'src/app/modals/ciudad.model';
import { DistritoMunicipal } from 'src/app/modals/distrito-municipal.model';
import { Sector } from 'src/app/modals/sector.model';
import { Seccion } from 'src/app/modals/seccion.model';
import { Injectable } from '@angular/core';
import { Direccion } from 'src/app/modals/direccion.model';

@Injectable({
  providedIn: 'root'
})
export class DireccionesService {
  constructor(private httpClient: HttpClient) {  }

  public getProvincias(): Observable<Provincia[]> {
    return this.httpClient.get<Provincia[]>(environment.apiUrl + `/api/Direcciones/GetProvincias`);
  }

  public getDirecciones(Cliente : string): Observable<Direccion[]> {
    return this.httpClient.get<Direccion[]>(environment.apiUrl + `/api/Direcciones/GetDirecciones?Cliente=${Cliente}`);
  }

  public getAliasDirecciones(Cliente : string): Observable<string[]> {
    return this.httpClient.get<string[]>(environment.apiUrl + `/api/Direcciones/GetAliasDirecciones?Cliente=${Cliente}`);
  }

  public getDireccion(ID : string): Observable<Direccion> {
    return this.httpClient.get<Direccion>(environment.apiUrl + `/api/Direcciones/GetDireccion?ID=${ID}`);
  }

  public getCiudades(provincia : number): Observable<Ciudad[]> {
    return this.httpClient.get<Ciudad[]>(environment.apiUrl + `/api/Direcciones/GetCiudades?Provincia=${provincia}`);
  }

  public getDistritosMunicipales(ciudad : number): Observable<DistritoMunicipal[]> {
    return this.httpClient.get<DistritoMunicipal[]>(environment.apiUrl + `/api/Direcciones/GetDistritoMunicipales?Ciudad=${ciudad}`);
  }

  public getSecciones(distrito : number): Observable<Seccion[]> {
    return this.httpClient.get<Seccion[]>(environment.apiUrl + `/api/Direcciones/GetSecciones?Distrito=${distrito}`);
  }

  public getSectores(seccion : number): Observable<Sector[]> {
    return this.httpClient.get<Sector[]>(environment.apiUrl + `/api/Direcciones/GetSectores?Seccion=${seccion}`);
  }

  public delete(ID : string): Observable<string> {
    return this.httpClient.get<string>(environment.apiUrl + `/api/Direcciones/Delete?ID=${ID}`);
  }

  public update(Direccion : Direccion): Observable<string> {
    return this.httpClient.post<string>(environment.apiUrl + `/api/Direcciones/Modify`, Direccion);
  }

  public add(Direccion : Direccion): Observable<number> {
    return this.httpClient.post<number>(environment.apiUrl + `/api/Direcciones/Add`, Direccion);
  }
}
