import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Orden } from 'src/app/modals/orden.model'
import { RespuestaTransferencia } from 'src/app/modals/respuesta.model'
import Swal from 'sweetalert2'
import { Md5 } from 'ts-md5'
import { CarritoService } from '../../shared/services/carrito.service'
import { OrdenService } from '../../shared/services/orden.service'
import { PaymentService } from '../../shared/services/payment.service'
import { ApiService } from '../api.service'

@Component({
  selector: 'app-modal-pagos',
  templateUrl: './modal-pagos.component.html',
  styleUrls: ['./modal-pagos.component.sass'],
  providers: [Md5]
})

export class ModalPagosComponent implements OnInit {
  public delivery: number
  public total: number
  public orden: Orden

  paymentForm = new FormGroup({
    merchantType: new FormControl('7997'),
    merchantNumber: new FormControl('349000000'),
    merchantTerminal: new FormControl('58585858'),

    returnUrl: new FormControl('https://casamichelrd.com/#/shop/orderSuccess'),
    cancelUrl: new FormControl('https://casamichelrd.com/#/shop/orderSuccess'),

    transactionType: new FormControl('200'),
    currencyCode: new FormControl('214'),
    acquiringInstitutionCode: new FormControl('349'),
    pageLanguaje: new FormControl('ESP'),
    ordenId: new FormControl(''),  //se llena desde el servicio en el onInit
    transactionId: new FormControl(''),  //se llena desde el servicio en el onInit
    amount: new FormControl(0), //se llena desde el servicio en el onInit
    tax: new FormControl(0), //se llena desde el servicio en el onInit
    merchantName: new FormControl('Casa Michel'),
    keyEncriptionKey: new FormControl(''), //se llena desde el servicio en el onInit
    creditcardNumber: new FormControl('', [Validators.required]), //se llena del form
    loteid: new FormControl(''),  //se llena desde el servicio en el onInit
    seqid: new FormControl(''),  //se llena desde el servicio en el onInit

    expdate_month: new FormControl('', [Validators.required]), //se llena del form
    expdate_year: new FormControl('', [Validators.required]), //se llena del form
    cvv: new FormControl('', [Validators.required, Validators.minLength(3)]), //se llena del form
    cliente: new FormControl(''), //se llena en el onInit
  })

  cardImages: string[] = ['assets/images/tarjetas/MASTERCARD.png', 'assets/images/tarjetas/AMEX.png', 'assets/images/tarjetas/VISA.png']
  cardImage: string = 'assets/images/tarjetas/NOCARD.png'

  constructor(public cartService: CarritoService, private paymentService: PaymentService,
    private router: Router, private apiService: ApiService,
    private ordenService: OrdenService, private _md5: Md5,
    @Inject(MAT_DIALOG_DATA) public data: Orden) {
    this.orden = data

    setTimeout(() => {
      if(data.sec > 0){
        this.paymentService.getSecOrder(this.apiService.getCustomerID(), this.orden.sec).subscribe(data => {
          this.paymentForm.patchValue({
            ordenId: this.orden.sec || 0,
            amount: this.orden.total,
            tax: this.orden.itbis,
            transactionId: data.transaccion,
            loteid: data.loteID,
            seqid: data.secId,
            cliente: this.apiService.getCustomerID()
          })
          this.setHash()
        })
      }
      else
      {
        this.paymentService.getSecOrder(this.apiService.getCustomerID()).subscribe(data => {
          this.paymentForm.patchValue({
            ordenId: this.orden.sec || 0,
            amount: this.orden.total,
            tax: this.orden.itbis,
            transactionId: data.transaccion,
            loteid: data.loteID,
            seqid: data.secId,
            cliente: this.apiService.getCustomerID()
          })

          this.setHash()
        })
      }
    }, 2000);

  }

  ngOnInit(): void {

  }

  public getTotal() {
    this.cartService.getTotalAmount().subscribe(subtotal => {
      this.total = subtotal + this.delivery
    })

    return this.total
  }

  // Get Delivery
  public getDelivery() {
    this.cartService.getDelivery().subscribe(data => {
      this.delivery = data
    })
  }

  public setHash() {
    const md5 = new Md5();

    let key = this._md5.appendStr(
      `${this.paymentForm.get('merchantType').value}
      ${this.paymentForm.get('merchantNumber').value}
      ${this.paymentForm.get('merchantTerminal').value}
      ${this.paymentForm.get('transactionId').value}
      ${this.paymentForm.get('amount').value}
      ${this.paymentForm.get('tax').value}`
    )

    this.paymentForm.patchValue({
      keyEncriptionKey: key.end()
    })
  }

  getITBIS(): number {
    var itbis = 0
    this.cartService.getITBISAmount().subscribe(data => {
      itbis = data
    })
    return itbis
  }

  setImage(): void {
    var AMEX = /^(?:3[47][0-9]{13})$/
    var VISA = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/
    var MASTERCARD = /^(?:5[1-5][0-9]{14})$/
    var value = this.paymentForm.get('creditcardNumber').value

    if (value.match(AMEX)) {
      this.cardImage = this.cardImages[1]
    }
    else if (value.match(VISA)) {
      this.cardImage = this.cardImages[2]
    }
    else if (value.match(MASTERCARD)) {
      this.cardImage = this.cardImages[0]
    }
  }

  response(responseCode): void {
    if (responseCode == '00') {
      Swal.fire({
        title: '¡Su transaccion ha sido autorizada!',
        text: 'Su pedido ya esta siendo procesado.',
        imageUrl: 'assets/images/logo-white.png',
        imageHeight: 120,
        imageAlt: 'Logo Casa Michel',
        showCancelButton: false,
        confirmButtonColor: '#62c639',
        confirmButtonText: 'Okay',
        allowOutsideClick: true
      })

      this.cartService.removeAllFromCart()
    }
    else if (responseCode == '51') {
      Swal.fire({
        title: 'Error',
        text: 'Fondos insuficientes',
        imageUrl: 'assets/images/logo-white.png',
        imageHeight: 120,
        imageAlt: 'Logo Casa Michel',
        showCancelButton: false,
        confirmButtonColor: '#62c639',
        confirmButtonText: 'Okay',
        allowOutsideClick: true
      })
    }
    else if (responseCode == '05') {
      Swal.fire({
        title: 'Error',
        text: 'Transacción rechazada',
        imageUrl: 'assets/images/logo-white.png',
        imageHeight: 120,
        imageAlt: 'Logo Casa Michel',
        showCancelButton: false,
        confirmButtonColor: '#62c639',
        confirmButtonText: 'Okay',
        allowOutsideClick: true
      })
    }
    else {
      Swal.fire({
        title: 'Error',
        text: 'Ocurrió un error interno durante la transacción',
        imageUrl: 'assets/images/logo-white.png',
        imageHeight: 120,
        imageAlt: 'Logo Casa Michel',
        showCancelButton: false,
        confirmButtonColor: '#62c639',
        confirmButtonText: 'Okay',
        allowOutsideClick: true
      })
    }
  }

  onSubmit() {
    var response: RespuestaTransferencia

    this.ordenService.saveOrden(this.orden).subscribe(data => {
      if (data != 'error') {

        if (this.paymentForm.valid) {
          this.paymentService.pay(this.paymentForm.value)
            .subscribe((data) => {
              response = data

              this.response(data.responseCode)
              this.router.navigateByUrl('/shop/orderSuccess')
            },
              error => console.log(error)
            )
        }
      } else {
        Swal.fire({
          title: 'Orden no procesada',
          text: 'La orden no ha sido procesada correctamente, inténtelo más tarde.',
          imageUrl: 'assets/images/logo-white.png',
          imageHeight: 120,
          imageAlt: 'Logo Casa Michel',
          showCancelButton: false,
          confirmButtonColor: '#62c639',
          confirmButtonText: 'Okay',
          allowOutsideClick: true
        })
      }
    })
  }

  payAgain(): void {
    var response: RespuestaTransferencia

    this.paymentService.payAgain(this.paymentForm.value)
      .subscribe((data) => {
        response = data
        this.response(data.responseCode)

        localStorage.setItem('responseCode', JSON.stringify(data))
        this.router.navigateByUrl('/shop/orderSuccess')
      },
        error => console.log(error)
      )
  }
}
