import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { Producto } from 'src/app/modals/producto.model';
import { Sucursal } from 'src/app/modals/sucursal.model';
import { ApiService } from '../../auth/api.service';

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class ProductoService {
  public currency : string = 'DOP ';
  public catalogMode : boolean = false;

  private _url: string = "assets/data/";
  public url = "assets/data/banners.json";

  public compareProducts : BehaviorSubject<Producto[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;

  constructor(private httpClient: HttpClient, public snackBar: MatSnackBar,
    private apiService : ApiService) {
   this.compareProducts.subscribe(products => products = products)
  }

  private sucursal() : string {
    return localStorage.getItem('sucursal') || "17";
  }

  private products(): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl + `/api/productos/Get?Sucursal=${this.sucursal()}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private random(): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl + `/api/productos/GetRandom?Sucursal=${this.sucursal()}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private productsByArea(id : number): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl + `/api/productos/GetProductsByArea?Sucursal=${this.sucursal()}&Area=${id}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private productsByOfertas(): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl + `/api/productos/GetProductsByOfertas?Sucursal=${this.sucursal()}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private productsByCompraUltimoMes(): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl + `/api/productos/GetProductsByCompraUltimoMes?Sucursal=${this.sucursal()}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private productsByMasVendido15Dias(): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl + `/api/productos/GetProductsByMasVendido15Dias?Sucursal=${this.sucursal()}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private productsByPrecioBajo2Meses(): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl + `/api/productos/GetProductsByPrecioBajo2Meses?Sucursal=${this.sucursal()}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private productByCode(id : number): Observable<Producto> {
    return this.httpClient.get<Producto>(environment.apiUrl + `/api/productos/GetByCode?Sucursal=${this.sucursal()}&Codigo=${id}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private productsBySearch(search : string): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl + `/api/productos/GetByFilter?Sucursal=${this.sucursal()}&Filtro=${search}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private productsByDpto(id : number): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl +  `/api/productos/GetProductsByDepartment?Sucursal=${this.sucursal()}&Department=${id}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private favorites(): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl +  `/api/productos/GetFavorites?Sucursal=${this.sucursal()}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private novedades(): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(environment.apiUrl +  `/api/productos/GetNovedades?Sucursal=${this.sucursal()}&Nivel=${this.getNivel()}&Factor=${this.getFactor()}`);
  }

  private sucursales(): Observable<Sucursal[]> {
    return this.httpClient.get<Sucursal[]>(environment.apiUrl + '/api/sucursales/Get');
  }

  public banners(): Observable<any[]>{
    return this.httpClient.get<any[]>(this.url);
  }

  public getFactor(): string {
    return this.apiService.getCustomer().factor > 0 ? this.apiService.getCustomer().factor.toString() : '1';
  }

  public getNivel(): string {
    return this.apiService.getCustomer().nivel > 0 ? this.apiService.getCustomer().nivel.toString() : '4';
  }

  // Get Banners
  public getBanners() {
    return this.banners();
  }

  // Get Products
  public getProducts(): Observable<Producto[]> {
    return this.products();
  }

  public getProductsByArea(id : number): Observable<Producto[]> {
    return this.productsByArea(id);
  }

  public getProductsByOfertas(): Observable<Producto[]> {
    return this.productsByOfertas();
  }

  public getProductsByCompraUltimoMes(): Observable<Producto[]> {
    return this.productsByCompraUltimoMes();
  }
  public getProductsByMasVendido15Dias(): Observable<Producto[]> {
    return this.productsByMasVendido15Dias();
  }
  public getProductsByPrecioBajo2Meses (): Observable<Producto[]> {
    return this.productsByPrecioBajo2Meses();
  }
  public getProductsBySearch(search : string): Observable<Producto[]> {
    return this.productsBySearch(search);
  }

  public getProductsByDpto(id : number): Observable<Producto[]> {
    return this.productsByDpto(id);
  }

  public getSucursales(): Observable<Sucursal[]> {
    return this.httpClient.get<Sucursal[]>(environment.apiUrl + '/api/sucursales/Get');
  }

  public  getNombreSucursales(): Observable<string[]> {
    return this.httpClient.get<string[]>(environment.apiUrl + '/api/sucursales/GetNombres');
  }

  public getFavorites(): Observable<Producto[]> {
    return this.favorites();
  }

  public getNovedades(): Observable<Producto[]> {
    return this.novedades();
  }

  public getRandom(): Observable<Producto[]> {
    return this.random();
  }

  public getProduct(id : number): Observable<Producto> {
    return this.productByCode(id);
  }

// Get Compare Products
public getComapreProducts(): Observable<Producto[]> {
  const itemsStream = new Observable(observer => {
    observer.next(products);
    observer.complete();
  });
  return <Observable<Producto[]>>itemsStream;
}

// If item is aleready added In compare
public hasProduct(product: Producto): boolean {
  const item = products.find(item => item.id === product.codigo);
  return item !== undefined;
}

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Producto> {
    return this.products().pipe(map(items => {
      return items.find((item: any) => {
        return item.descripcion.replace(' ', '-') === slug;
      });
    }));
  }

 // Add to compare
 public addToCompare(product: Producto): Producto | boolean {
  let message, status;
  var item: Producto | boolean = false;
  if (this.hasProduct(product)) {
    item = products.filter(item => item.id === product.codigo)[0];
    const index = products.indexOf(item);
    this.snackBar.open('The product  ' + product.descripcion + ' already added to comparison list.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

  } else {
    if(products.length < 4)
      products.push(product);
      message = 'The product ' + product.descripcion + ' has been added to comparison list.';
      status = 'success';
      this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });

  }
    localStorage.setItem("compareItem", JSON.stringify(products));
    return item;
}

// Removed Producto
public removeFromCompare(product: Producto) {
  if (product === undefined) { return; }
  const index = products.indexOf(product);
  products.splice(index, 1);
  localStorage.setItem("compareItem", JSON.stringify(products));
}

   // Get Products By category
   public getProductByCategory(category: string): Observable<Producto[]> {
    return this.products().pipe(map(items =>
       items.filter((item: Producto) => {
         if(category == 'all')
            return item
            else
            return item.area=== category;

       })
     ));
  }

}
