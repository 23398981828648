import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatExpansionPanel } from '@angular/material/expansion'
import { MatRadioChange } from '@angular/material/radio'
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { CartItem } from 'src/app/modals/cart-item'
import { Cliente } from 'src/app/modals/cliente.model'
import { Direccion } from 'src/app/modals/direccion.model'
import { Orden } from 'src/app/modals/orden.model'
import { Sucursal } from 'src/app/modals/sucursal.model'
import Swal from 'sweetalert2'
import { ApiService } from '../auth/api.service'
import { ModalPagosComponent } from '../auth/modal-pagos/modal-pagos.component'
import { CarritoService } from '../shared/services/carrito.service'
import { ClienteService } from '../shared/services/cliente.service'
import { DireccionesService } from '../shared/services/direccion.service'
import { OrdenService } from '../shared/services/orden.service'
import { ProductoService } from '../shared/services/producto.service'

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass']
})
export class CheckoutComponent implements OnInit {

  public cartItems: Observable<CartItem[]> = of([])
  public buyProducts: CartItem[] = []

  public sucursalesList: Sucursal[] = []
  public sucursal: string

  public direccionesList: Direccion[] = []
  public direccion: string

  public procesing: boolean
  public precioDelivery: number;

  isDelivery: boolean = true;
  isClienteSim: boolean = false;
  public total: number

  cliente: Cliente
  payment: number = 5
  deliveryWay: string[] = ['Recogida en tienda', 'Envío a mi dirección']

  public orden: Orden

  constructor(public cartService: CarritoService, private direccionService: DireccionesService,
    public productService: ProductoService, private apiService: ApiService,
    private ordenService: OrdenService, private router: Router,
    public dialog: MatDialog, private clienteService: ClienteService) {

    this.getDelivery();
    this.getTotal();
    this.getIsClienteSim();

    this.productService.getSucursales().subscribe(data => {
      this.sucursalesList = data

      var suc = JSON.stringify(localStorage.getItem('sucursal'))

      if (suc != '0') {
        var sucursal = this.sucursalesList.filter((item: Sucursal) => {
          return item.id == JSON.parse(localStorage.getItem('sucursal'))
        })

        this.sucursal = sucursal[0].nombre
      }
    })

    this.cliente = JSON.parse(localStorage.getItem('CustomerCM'))

    this.direccionService.getDirecciones(this.cliente.codigo).subscribe(data => {
      this.direccionesList = data

      if (localStorage.getItem('direccion') != '0') {
        var direccion = this.direccionesList.filter((item: Direccion) => {
          return item.id == JSON.parse(localStorage.getItem('direccion'))
        })

        this.direccion = direccion[0].alias
      }
    })
  }

  public getEntrega(): number {
    var entrega = +localStorage.getItem('direccion')
    if (entrega != 0) {
      return entrega
    }
    return 0
  }

  public setPayment(payment) {
    this.payment = payment
  }

  getIsClienteSim(){
    const cliente:Cliente = JSON.parse(localStorage.getItem('CustomerCM'));

    this.clienteService.isClienteSim(cliente.codigo).subscribe(response =>{
      this.isClienteSim = response;
    });


  }

  public setDelivery(bool: boolean) {

    if (bool == true) {
      if (!this.isDelivery) {
        this.getDelivery();
        this.total += this.precioDelivery;
        this.isDelivery = true;
      }
    } else if (bool == false) {
      if (this.isDelivery) {
        this.getDelivery();
        this.total -= this.precioDelivery;
        this.precioDelivery = 0;
        this.isDelivery = false;
      }
    }

  }

  //1. efectiv 2. deposito, 3. tarjeta en local, 4.
  public llenaOrden() {
    this.orden = new Orden
    this.orden.cliente = this.cliente.codigo
    this.orden.empleador = 0
    this.orden.empresa = JSON.parse(localStorage.getItem('sucursal'))
    this.orden.fecha = new Date
    this.orden.items = JSON.parse(localStorage.getItem('cartItemCM'))
    this.orden.iDSecOrdenTR = 0
    this.orden.formaPago = this.payment
    this.cartService.getITBISAmount().subscribe(data => this.orden.itbis = data)
    this.orden.lugarEntrega = this.getEntrega()
    this.orden.nFisicoPedido = ''
    this.orden.observaciones = ''
    this.orden.quienRecibira = ''
    this.orden.telefono = this.cliente.telefono1
    this.orden.cargoDelivery = this.precioDelivery
    this.orden.pagoAprobado = false
    this.orden.sec = 0
    this.orden.total = this.total
  }

  onChange(radio: MatRadioChange, panel: MatExpansionPanel, antPanel: MatExpansionPanel) {
    antPanel.close()
    panel.open()

  }

  ngOnInit() {
    this.cartItems = this.cartService.getItems()
    this.cartItems.subscribe(products => this.buyProducts = products)
    this.setDelivery(false)
  }

  // Get Total
  public getTotal() {
    this.cartService.getTotalAmount().subscribe(subtotal => {
      this.total = subtotal + this.precioDelivery
    })
  }

  // Get Delivery
  public getDelivery() {
    this.cartService.getDelivery().subscribe(data => {
      this.precioDelivery = data
    })
  }

  // Get SubTotal
  public getSubTotal(): Observable<number> {
    return this.cartService.getSubTotalAmount()
  }

  // Get Itbis
  public getITBIS(): Observable<number> {
    return this.cartService.getITBISAmount()
  }

  public changeSucursal(sucursal: Sucursal) {
    this.sucursal = sucursal.nombre.toString()
    localStorage.setItem('sucursal', JSON.stringify(sucursal.id))
    localStorage.setItem('direccion', JSON.stringify(0))
    this.getDelivery()
    this.getTotal()
  }

  public changeDireccion(direccion: Direccion) {
    this.direccion = direccion.alias.toString()
    localStorage.setItem('direccion', JSON.stringify(direccion.id))
    localStorage.setItem('sucursal', JSON.stringify(15))
    this.getDelivery()
    this.getTotal()
  }

  habilitaCheckout() {
    this.procesing = false
  }

  loggedIn() : boolean {
    return this.apiService.isLoggedIn();
  }

  public placeOrder() {
    this.procesing = true

    this.llenaOrden()
    if (this.orden.formaPago === 5) {
      const dialogRef = this.dialog.open(ModalPagosComponent, {
        width: '400px',
        data: this.orden
      })

      dialogRef.afterClosed().subscribe(result => {
        this.procesing = false
      });
    }
    else {
      this.ordenService.saveOrden(this.orden).subscribe(data => {
        if (data != 'error') {
          this.cartService.removeAllFromCart()
          Swal.fire({
            title: 'Orden procesada',
            text: 'La orden ha sido procesada correctamente.',
            imageUrl: 'assets/images/logo-white.png',
            imageHeight: 120,
            imageAlt: 'Logo Casa Michel',
            showCancelButton: false,
            confirmButtonColor: '#62c639',
            confirmButtonText: 'Okay',
            allowOutsideClick: true
          })

          // setTimeout(() => {
          //   this.cartService.removeAllFromCart()
          // }, 3000);

          this.cartService.removeAllFromCart()
          this.router.navigateByUrl('/shop/orderSucces')
        } else {
          Swal.fire({
            title: 'Orden no procesada',
            text: 'La orden no ha sido procesada correctamente, inténtelo más tarde.',
            imageUrl: 'assets/images/logo-white.png',
            imageHeight: 120,
            imageAlt: 'Logo Casa Michel',
            showCancelButton: false,
            confirmButtonColor: '#62c639',
            confirmButtonText: 'Okay',
            allowOutsideClick: true
          })
        }
      })
    }
  }


}
