import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Cliente } from 'src/app/modals/cliente.model';
import { Injectable } from '@angular/core';
import { Dpto } from 'src/app/modals/dpto.model';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private httpClient: HttpClient) { }

  public getCliente(cliente: Cliente): Observable<Cliente> {
    return this.httpClient.post<Cliente>(environment.apiUrl + '/api/Clientes/Login', cliente);
  }

  public UpdateCliente(cliente: Cliente): Observable<Cliente> {
    return this.httpClient.post<Cliente>(environment.apiUrl + '/api/Clientes/Modify', cliente);
  }

  public RegisterCliente(cliente: Cliente): Observable<number> {
    return this.httpClient.post<number>(environment.apiUrl + '/api/Clientes/Add', cliente);
  }

  public RegisterClienteXMayor(cliente: Cliente): Observable<Cliente> {
    return this.httpClient.post<Cliente>(environment.apiUrl + '/api/Clientes/SolicitudXMayor', cliente);
  }

  public getClienteByCode(codigo: number): Observable<Cliente> {
    return this.httpClient.post<Cliente>(environment.apiUrl + '/api/Clientes/Get', codigo);
  }

  public passwordRecovery(Email: string): Observable<number> {
    return this.httpClient.get<number>(environment.apiUrl + `/api/Clientes/passwordRecovery?Email=${Email}`);
  }

  public isClienteSim(cliente: string): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.apiUrl + `/api/Clientes/IsClienteSim?cliente=${cliente}`);
  }
  public validateCode(codigo: string): Observable<string> {
    return this.httpClient.get<string>(environment.apiUrl + `/api/Clientes/ValidateCode?Code=${codigo}`);
  }

  public changePassword(codigo : string, password: string): Observable<string> {
    return this.httpClient.get<string>(environment.apiUrl + `/api/Clientes/ChangePassword?Codigo=${codigo}&Password=${password}`);
  }

  //FUNCIONES PARA ACTUALIZAR LOS DATOS DE LOS CLIENTES SIM
  public UpdateData(Cliente : Cliente): Observable<number> {
    return this.httpClient.post<number>(environment.apiUrl + `/api/Clientes/UpdateDataCustomer`, Cliente);
  }

  public getClienteSIM(Codigo : number): Observable<Cliente> {
    return this.httpClient.get<Cliente>(environment.apiUrl + `/api/Clientes/GetClienteSIM?Codigo=${Codigo}`);
  }

  public getTipos(): Observable<Dpto[]> {
    return this.httpClient.get<Dpto[]>(environment.apiUrl + `/api/Clientes/GetTipos`);
  }
}
