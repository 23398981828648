import { Component, OnInit } from '@angular/core';
import { AreaService } from 'src/app/components/shared/services/area.service';
import { Area } from 'src/app/modals/area.model';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.sass']
})
export class AreasComponent implements OnInit {
  areasList : Area[] = [];

  constructor(private areaService : AreaService) {
    this.areaService.getAreas().subscribe(data => {
      this.areasList = data;
    })
  }

  ngOnInit(): void {  }

  saveArea(area : Area) {
    localStorage.setItem('area', JSON.stringify(area));
    localStorage.setItem('dpto', '0');
  }
}
