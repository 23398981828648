import { Component, OnInit, Inject } from '@angular/core';
import { ProductoService } from 'src/app/components/shared/services/producto.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Producto } from 'src/app/modals/producto.model';
import { CarritoService } from 'src/app/components/shared/services/carrito.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.sass']
})
export class ProductDialogComponent implements OnInit {

  public products: Producto[] = [];
  public counter: number = 1;
  public variantImage: any = '';
  public selectedColor: any = '';
  public selectedSize: any = '';

  constructor(private router: Router, public productsService: ProductoService,
    private cartService: CarritoService, public dialogRef: MatDialogRef<ProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public product: Producto) { }

  ngOnInit() {
    this.productsService.getProducts().subscribe(product => this.products = product);

  }

  public addToCart(product: Producto, quantity) {
    if (quantity == 0) return false;
    this.cartService.addToCart(product, parseInt(quantity));
  }

  public close(): void {
    this.dialogRef.close();
  }

  public increment() {
    this.counter += 1;
  }

  public decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
    }
  }

  // Add to cart
  public buyNow() {
    this.router.navigate(['/product', this.product.codigo]);
    this.close();
  }

}
