import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from '../../auth/api.service';

@Component({
  selector: 'app-footer-two',
  templateUrl: './footer-two.component.html',
  styleUrls: ['./footer-two.component.css']
})
export class FooterTwoComponent implements OnInit {

  constructor(private apiService : ApiService) { }

  ngOnInit() {

  }

  loggedIn() : boolean {
    return this.apiService.isLoggedIn();
  }

  goToUrl() {
    window.location.href = 'www.hptronics.com';
  }

}
