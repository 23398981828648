// Producto Tag
export type ProductoTags = 'nike' | 'puma' | 'lifestyle' | 'caprese';

// Producto Colors
export type ProductColor = 'white' | 'black' | 'red' | 'green' | 'purple' | 'yellow' | 'blue' | 'gray' | 'orange' | 'pink';

export class Producto {
  sec?: number;
  codigo?: string;
  codBar?: string;
  cod_Fab?: string;
  descripcion?: string;
  und?: string;
  empaque?: number;
  detalle?: string;
  itbis?: boolean;
  porITBIS?: number;
  precio?: number;
  precioOferta?: number;
  costo?: number;
  precioLista?: number;
  xmayor1?: number;
  xmayor2?: number;
  xmayor3?: number;
  precioMinimo?: number;
  enoferta?: number;
  areaid?: number;
  dptoID?: number;
  grupoID?: number;
  area?: string;
  dpto?: string;
  grupo?: string;
  foto?: string;
  inventario?: number;
  pesado?: boolean;
  addCantidad?: number;
  agregado?: number;
  descripcionLarga?: string;
  codigoJuntos?: string;
  fotosAlterna?: string[];
  productosLink?: Producto[];
  link?: number;
  state?: string;
  comentario?: string;
  personaje?: string;
  edades?: string;
  tamano?: string;
  color?: string;
  personajeId?:number;

  constructor(
    codigo?: string,
    descripcion?: string,
    precio?: number,
    areaid?: number,
    dptoid?: number,
    grupoid?: number,
    area?: string,
    dpto?: string,
    grupo?: string,
    sec?: number,
    codbar?: string,
    cod_fab?: string,
    und?: string,
    empaque?: number,
    detalle?: string,
    itbis?: boolean,
    porITBIS?: number,
    precioOferta?: number,
    costo?: number,
    precioLista?: number,
    xmayor1?: number,
    xmayor2?: number,
    xmayor3?: number,
    precioMinimo?: number,
    enoferta?: number,
    foto?: string,
    inventario?: number,
    pesado?: boolean,
    addCantidad?: number,
    agregado?: number,
    descripcionLarga?: string,
    codigoJuntos?: string,
    fotosAlterna?: string[],
    productosLink?: Producto[],
    link?: number,
    state?: string,
    comentario?: string,
    personaje?: string,
    edades?: string,
    tamano?: string,
    color?: string,
    personajeId?: number
  ) {
    this.sec = sec;
    this.codigo = codigo;
    this.codBar = codbar;
    this.cod_Fab = cod_fab;
    this.descripcion = descripcion;
    this.und = und;
    this.empaque = empaque;
    this.detalle = detalle;
    this.itbis = itbis;
    this.porITBIS = porITBIS;
    this.precio = precio;
    this.precioOferta = precioOferta;
    this.costo = costo;
    this.precioLista = precioLista;
    this.xmayor1 = xmayor1;
    this.xmayor2 = xmayor2;
    this.xmayor3 = xmayor3;
    this.precioMinimo = precioMinimo;
    this.enoferta = enoferta;
    this.areaid = areaid;
    this.dptoID = dptoid;
    this.grupoID = grupoid;
    this.area = area;
    this.dpto = dpto;
    this.grupo = grupo;
    this.foto = foto;
    this.inventario = inventario;
    this.pesado = pesado;
    this.addCantidad = addCantidad;
    this.agregado = agregado;
    this.descripcionLarga = descripcionLarga;
    this.codigoJuntos = codigoJuntos;
    this.fotosAlterna = fotosAlterna;
    this.productosLink = productosLink;
    this.link = link;
    this.state = state;
    this.comentario = comentario;
    this.personaje= personaje;
    this.edades= edades;
    this.tamano= tamano;
    this.color= color;
    this.personajeId=personajeId;
  }
}

// Color Filter
export interface ColorFilter {
  color?: ProductColor;
}
