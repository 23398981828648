import { Component, OnInit } from '@angular/core';
import { AreaService } from 'src/app/components/shared/services/area.service';
import { Area } from 'src/app/modals/area.model';
import { Dpto } from 'src/app/modals/dpto.model';
import { DptoService } from '../services/dpto.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})

export class MenuComponent implements OnInit {
  public departmentsList: Dpto[] = [];
  public areasList: Area[] = [];

  constructor(private areaService: AreaService, private dptoService: DptoService) {
    this.areaService.getAreas().subscribe(data => {
      this.areasList = data;
    })

    this.dptoService.getDptos().subscribe(data => {
      this.departmentsList = data;
    })
  }

  ngOnInit() {

  }

  saveDpto(area : Area, dpto : Dpto) {
    localStorage.setItem('area', JSON.stringify(area));
    localStorage.setItem('dpto', JSON.stringify(dpto));
  }

  saveArea(area : Area) {
    localStorage.setItem('area', JSON.stringify(area));
    localStorage.setItem('dpto', '0');
  }

  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }
    });
  }

}
