import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ClienteService } from '../../shared/services/cliente.service';
import { Cliente } from 'src/app/modals/cliente.model';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.sass']
})

export class EditProfileComponent implements OnInit {

  editForm: FormGroup = this.fb.group({
    nombre:  ['', [ Validators.required ] ],
    apellidos: ['', [ Validators.required ] ],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [ Validators.required,Validators.minLength(8) ] ],
    confirmarPassword: ['', [ Validators.required, Validators.minLength(8) ] ],
    rNC: [''],
    cedula: ['', [ Validators.required, Validators.minLength(11) ] ],

    telefono1: [''],
    tel1tp: [''],
    telefono2: [''],
    tel2tp: [''],
    telefono3: [''],
    tel3tp: ['' ],

    comentarios: [''],
    ncf: [''],
    contacto: [''],
  });

  cliente : Cliente;

  constructor(public fb : FormBuilder, private clienteService : ClienteService) {
    this.cliente = JSON.parse(localStorage.getItem('CustomerCM'));
  }

  ngOnInit(): void {

  }

  edit(): void {
    this.clienteService.UpdateCliente(this.editForm.value).subscribe(data => {
      if(data == 'success'){
        Swal.fire({
          title: 'Exito',
          text: 'Cambios guardados exitósamente.',
          timer: 1500
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'No se ha podido guardar.',
          timer: 1500
        });
      }
    });
  }

  public getError(controlName: string): string {
    if (this.editForm.get(controlName).hasError('required')) {
      return `El campo ${controlName} es obligatorio.`;
    } else if (this.editForm.get(controlName).hasError('email')) {
      return `El campo ${controlName} debe ser un email válido.`;
    } else if (this.editForm.get(controlName).hasError('minlength') && controlName === 'password' || controlName === 'confirmarPassword') {
      return `El campo ${controlName} debe tener como mínimo 8 caracteres.`;
    } else if (this.editForm.get(controlName).hasError('minlength') && controlName === 'cedula') {
      return `El campo ${controlName} debe tener como mínimo 11 caracteres.`;
    }

    return '';
  }

}
