import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DptoService } from 'src/app/components/shared/services/dpto.service';
import { Area } from 'src/app/modals/area.model';
import { Dpto } from 'src/app/modals/dpto.model';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.sass']
})
export class DepartmentsComponent implements OnInit {
  @Input() departmentList : Dpto[] = [];
  @Output() dptoChanged = new EventEmitter();

  constructor(private dptoService : DptoService) {

  }

  ngOnInit(): void {  }

  dptoSelect(event) {
    localStorage.setItem('dpto', JSON.stringify(event.value));

    this.dptoChanged.emit(
      event.value
    );
  }
}
