import { CartItem } from "./cart-item";
import { Producto } from "./producto.model";

export class Orden {
  public sec : number;
  public nFisicoPedido : string;
  public cliente : string;
  public empleador : number;
  public fecha : Date;
  public observaciones : string;
  public empresa : string;
  public total : number;
  public itbis : number;
  public pending : string;
  public nulo : string;

  public formaPago : number;
  public lugarEntrega : number;
  public fechaEntrega : number;
  public items : CartItem[];
  public productos : Producto[];
  public quienRecibira : string;
  public telefono : string;
  public cargoDelivery : number;
  public iDSecOrdenTR : number;
  public pagoAprobado : boolean;
  public estatus : string;
}
