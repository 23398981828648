import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filosofia',
  templateUrl: './filosofia.component.html',
  styleUrls: ['./filosofia.component.sass']
})
export class FilosofiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
