import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Ciudad } from 'src/app/modals/ciudad.model';
import { Direccion } from 'src/app/modals/direccion.model';
import { DistritoMunicipal } from 'src/app/modals/distrito-municipal.model';
import { Provincia } from 'src/app/modals/provincia.model';
import { Seccion } from 'src/app/modals/seccion.model';
import { Sector } from 'src/app/modals/sector.model';
import Swal from 'sweetalert2';
import { DireccionesService } from '../../shared/services/direccion.service';
import { ProductoService } from '../../shared/services/producto.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.sass']
})
export class AddAddressComponent implements OnInit {
  provincias : Provincia[] = [];
  ciudades : Ciudad[] = [];
  distritos : DistritoMunicipal[] = [];
  secciones : Seccion[] = [];
  sectores : Sector[] = [];
  direccion : Direccion = {};
  codigo : number;

  editForm: FormGroup = this.fb.group({
    codigo: [JSON.parse(localStorage.getItem('CustomerCM')).codigo, [ Validators.required ] ],
    alias: ['', [ Validators.required ] ],
    direccion: [this.direccion.direccion, [ Validators.required ] ],
    provincia: ['', [ Validators.required ] ],
    ciudad: ['', [ Validators.required ] ],
    distritoMunicipal: ['', [ Validators.required ] ],
    seccion: ['', [ Validators.required ] ],
    sector: ['', [ Validators.required ] ],
    referencias: ['', [ Validators.required ] ],
  });

  constructor(public fb: FormBuilder, public direccionService: DireccionesService,
              public productsService: ProductoService, private router : Router) {
    this.codigo = JSON.parse(localStorage.getItem('CustomerCM')).codigo;
  }

  ngOnInit(): void {
    this.direccionService.getProvincias().subscribe(data => this.provincias = data);
  }

  public getError(controlName: string): string {
    if(this.editForm.get(controlName) != null){
      if (this.editForm.get(controlName).hasError('required')) {
        return `El campo ${controlName} es obligatorio.`;
      } else if (this.editForm.get(controlName).hasError('email')) {
        return `El campo ${controlName} debe ser un email válido.`;
      } else if (this.editForm.get(controlName).hasError('minlength') && controlName === 'password' || controlName === 'confirmarPassword') {
        return `El campo ${controlName} debe tener como mínimo 8 caracteres.`;
      } else if (this.editForm.get(controlName).hasError('minlength') && controlName === 'cedula') {
        return `El campo ${controlName} debe tener como mínimo 11 caracteres.`;
      }
    }

    return '';
  }

  public add(){
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    this.direccionService.add(this.editForm.value).subscribe(data => {
      if(data > 0){
        Toast.fire({
          icon: 'success',
          title: 'Guardada exitósamente.'
        })
        this.router.navigateByUrl('/auth/profile')
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Error al guardar, intente nuevamente.'
        })
      }
    })
  }

  cambioProvincia(provincia : number) : void {
    this.direccionService.getCiudades(provincia).subscribe(data => this.ciudades = data);
  }

  cambioCiudad(ciudad : number) : void {
    this.direccionService.getDistritosMunicipales(ciudad).subscribe(data => this.distritos = data);
  }

  cambioDistrito(seccion : number) : void {
    this.direccionService.getSecciones(seccion).subscribe(data => this.secciones = data);
  }

  cambioSeccion(seccion : number) : void {
    this.direccionService.getSectores(seccion).subscribe(data => this.sectores = data);
  }
}
