import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ClienteService } from '../../shared/services/cliente.service';

@Component({
  selector: 'app-validate-code',
  templateUrl: './validate-code.component.html',
  styleUrls: ['./validate-code.component.sass']
})
export class ValidateCodeComponent implements OnInit {
  codeForm = new FormGroup({
    codigo: new FormControl('', [Validators.required])
  })

  cliente : string;

  constructor(private router: Router, private clienteService: ClienteService) { }

  ngOnInit(): void { }

  validate() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    if (this.codeForm.valid) {

      var code = this.codeForm.get('codigo').value.substring(0,3) + '-' + this.codeForm.get('codigo').value.substring(3,6);

      this.clienteService.validateCode(code).subscribe(data => {
        if(data != '0'){
          Toast.fire({
            icon: 'success',
            title: 'Código validado.'
          });
          localStorage.setItem('Codigo', data);
          this.router.navigateByUrl('/auth/changePassword');
        }
        else
        {
          Toast.fire({
            icon: 'success',
            title: 'Código incorrecto.'
          });
        }
      });
    }
  }
}
