import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonResponse } from './commonResponse';
import { Cliente } from 'src/app/modals/cliente.model';import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  loginStatus = new BehaviorSubject<boolean>(this.hasToken());
  cliente: Observable<Cliente>;
  customer: Cliente;

  constructor(public dialog: MatDialog, private http: HttpClient,
              private cookieService: CookieService, private router: Router) {

  }
  /**
   *
   * @param formData as the login form data
   */
  login(formData: any): Observable<HttpResponse<CommonResponse>> {
    return this.http.post<CommonResponse>(environment.apiUrl + "/api/Clientes/Login", formData, { observe: 'response' })
      .pipe(
        tap((resp: HttpResponse<CommonResponse>) => {
          if (resp.headers.get('x-auth')) {
            this.cookieService.set("currentUser", resp.headers.get('x-auth'));
            this.loginStatus.next(true);
          }

          this.cookieService.set("currentUser", resp.headers.get('x-auth'));
          this.loginStatus.next(true);

          return resp;
        }),
        catchError(this.handleError)
      );
  }

  /**
   *
   * @param error error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Ha ocurrido un error:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Error #${error.status}, ` +
        `Error: ${error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError('Ha ocurrido un error.');
  };

  logout() {
    this.loginStatus.next(false);
    this.cookieService.deleteAll();
    this.customer = new Cliente('0', 4, 1);
    localStorage.setItem('CustomerCM', JSON.stringify(this.customer));
    this.router.navigate(['/auth/login']);
  }

  getCustomerID() : string {
    if(localStorage.getItem('CustomerCM') != null){
      this.customer = JSON.parse(localStorage.getItem('CustomerCM'));
      if(this.customer.codigo != null){
        return this.customer.codigo;
      }
      else {
        return '0';
      }
    }
  }

  getCustomer() : Cliente {
    if(localStorage.getItem('CustomerCM') != null){
      this.customer = JSON.parse(localStorage.getItem('CustomerCM'));
      if(this.customer.codigo != null){
        return this.customer;
      }
    } else {
      this.customer = new Cliente('0', 4, 1);
      localStorage.setItem('CustomerCM', JSON.stringify(this.customer));
      return this.customer;
    }
  }

  /**
  *
  * @returns {Observable<T>}
  */
  isLoggedIn(): boolean {
    //return this.loginStatus.asObservable();
    if(this.getCustomerID() != '0'){
      return true;
    } else {
      return false;
    }
  }
  /**
   * if we have token the user is loggedIn
   * @returns {boolean}
   */
  private hasToken(): boolean {
    return this.cookieService.check('currentUser');
  }
}
