import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Personaje } from 'src/app/modals/personajes';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonajeService {

  constructor(private httpClient: HttpClient) {  }

  public getPersonajes(): Observable<Personaje[]> {
    return this.httpClient.get<Personaje[]>(environment.apiUrl + `/api/Personaje/Get`);
  }
}
