import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ClienteService } from '../../shared/services/cliente.service';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.sass']
})
export class RestorePasswordComponent implements OnInit {
  restoreForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })

  constructor(private router: Router, private clienteService: ClienteService) {

  }

  ngOnInit(): void { }

  onSubmit() {
    if (this.restoreForm.valid) {
      this.clienteService.passwordRecovery(this.restoreForm.get('email').value).subscribe(data => {

        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });

        if(data != 0)
        {
          Toast.fire({
            icon: 'success',
            title: 'Correo enviado exitósamente.'
          });

        this.router.navigateByUrl('/auth/validateCode');
        }
        else
        {
          Toast.fire({
            icon: 'error',
            title: 'No se ha podido enviar el correo, intentelo de nuevo más tarde.'
          })
        }

      });
    }
  }
}
