import { Component, OnInit, HostBinding, Input, ElementRef } from '@angular/core';
import { SidebarMenuService } from './sidebar-menu.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { SidenavMenu } from './sidebar-menu.model';
import { Router } from '@angular/router';
import { Area } from 'src/app/modals/area.model';
import { MatSidenav } from '@angular/material/sidenav';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SidebarComponent implements OnInit {
  expanded: boolean;
  // @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: SidenavMenu;
  @Input() depth: number;
  @Input() sidNav: ElementRef;
  constructor(private sidenavMenuService:SidebarMenuService, public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.sidenavMenuService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
      }
    });
  }

  onItemSelected(item: SidenavMenu) {
    if (!item.children || !item.children.length) {
     
      this.router.navigate([item.route]);
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  saveArea(area : Area) {
    localStorage.setItem('area', JSON.stringify(area));
    localStorage.setItem('dpto', '0');
  }
}
