import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonajeService } from 'src/app/components/shared/services/personaje.service';
import { Personaje } from 'src/app/modals/personajes';

@Component({
  selector: 'app-personsajes',
  templateUrl: './personsajes.component.html',
  styleUrls: ['./personsajes.component.sass']
})
export class PersonsajesComponent implements OnInit {

  @Input() personajeList : Personaje[] = [];
  @Output() personajeChanged = new EventEmitter();

  constructor(private personajeService : PersonajeService) {

  }

  ngOnInit(): void {  }

  personajeSelect(event) {
    this.personajeChanged.emit(
      event.value
    );
  }
}
