import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import Swal from 'sweetalert2';
import { ApiService } from '../auth/api.service';
import { ProductoService } from '../shared/services/producto.service';
import { CarritoService } from './../shared/services/carrito.service';

@Component({
  selector: 'app-shopping-cart-component',
  templateUrl: './shopping-cart-component.component.html',
  styleUrls: ['./shopping-cart-component.component.sass']
})
export class ShoppingCartComponentComponent implements OnInit {
  public cartItems : Observable<CartItem[]> = of([]);
  public shoppingCartItems  : CartItem[] = [];

  constructor(private cartService: CarritoService, public productService: ProductoService,
              private apiService : ApiService) { }

  ngOnInit() {
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.confirmBox();
  }

  // Remove cart items
  public removeItem(item: CartItem) {
    this.cartService.removeFromCart(item);
  }

  // Increase Producto Quantity
   public increment(product: any, quantity: number = 1) {
    this.cartService.updateCartQuantity(product,quantity);
  }

  // Decrease Producto Quantity
  public decrement(product: any, quantity: number = -1) {
    this.cartService.updateCartQuantity(product,quantity);
  }

  // Get SubTotal
  public getSubTotal(): Observable<number> {
    return this.cartService.getSubTotalAmount();
  }

  // Get Itbis
  public getITBIS(): Observable<number> {
    return this.cartService.getITBISAmount();
  }

  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  loggedIn() : boolean {
    return this.apiService.isLoggedIn();
  }

  confirmBox(){
    Swal.fire({
      title: 'Información importante',
      text: 'Orden sujeta a variaciones según disponibilidad en tienda. Un vendedor le estará contactando para modificaciones y variaciones en el costo.',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Okay'
    }).then((result) => {

    })
  }
}
