import { Component, OnInit } from '@angular/core';
import { Producto } from 'src/app/modals/producto.model';
import { ProductoService } from 'src/app/components/shared/services/producto.service';

@Component({
  selector: 'app-popular-products',
  templateUrl: './popular-products.component.html',
  styleUrls: ['./popular-products.component.sass']
})
export class PopularProductsComponent implements OnInit {

  public products: Producto[];
  public product: Producto = {};

  constructor(public productService: ProductoService) { }

  ngOnInit() {
    this.productService.getRandom().subscribe(product => this.products = product);
  }
}
