import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../../shared/services/carrito.service';

@Component({
  selector: 'app-order-success2',
  templateUrl: './order-success2.component.html',
  styleUrls: ['./order-success2.component.sass']
})
export class OrderSuccess2Component implements OnInit {

  constructor(private cartService : CarritoService) {
    this.cartService.removeAllFromCart();
    localStorage.removeItem("cartItemCM")
  }

  ngOnInit(): void {

  }

  deleteAll() : void {
    localStorage.removeItem('responseCode')
    localStorage.removeItem('area')
    localStorage.removeItem('dpto')
    this.cartService.removeAllFromCart()
  }


}
