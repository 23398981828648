import { Component, OnInit } from '@angular/core';
import { Cliente } from 'src/app/modals/cliente.model';
import { Direccion } from 'src/app/modals/direccion.model';
import Swal from 'sweetalert2';
import { ClienteService } from '../../shared/services/cliente.service';
import { DireccionesService } from '../../shared/services/direccion.service';
import { ApiService } from "../api.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit {
  Cliente : Cliente;
  direccionList : Direccion[] = [];
  Codigo : number;

  constructor(private apiService:ApiService, private clienteService: ClienteService,
    private direccionService: DireccionesService) {
    this.Cliente = JSON.parse(localStorage.getItem('CustomerCM'));
    this.direccionService.getDirecciones(this.Cliente.codigo).subscribe(data => {
      this.direccionList = data;
    })
  }

  ngOnInit() {  }

  logout(){
    this.apiService.logout();
  }

  delete(ID : string){
    this.direccionService.delete(ID).subscribe(data => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

      if(data != '0'){
        Toast.fire({
          icon: 'success',
          title: 'Eliminada exitósamente.'
        });
      } else {
        Toast.fire({
          icon: 'success',
          title: 'Error al eliminar.'
        });
      }
    });
  }
}
